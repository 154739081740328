import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SESSION_STATE, selectSessionState } from '../../store/sessionDetails';
import { AccountDetails, SessionDetails } from '../../store/types';
import useJoinRoom from '../../components/VideoProvider/useJoinRoom/useJoinRoom';
import { createUser, updateIpOfUser } from '../../utils/firebaseServices/userService';
import { joinRoom } from '../../utils/firebaseServices/roomService';
import { SET_ROOM_DETAILS, selectCreateRoomState } from '../../store/createRoom';
import { RootState } from '../../store/store';
import { SET_USER_DETAILS, initialState, selectAccount } from '../../store/userDetails';
import { GetToken, telehealthtoken } from '../../firebase/cloudServices';
import useVideoContext from '../useVideoContext/useVideoContext';
import { SET_LOADER } from '../../store/loaders';
import { db } from '../../firebase/db.config';
import { COLLECTIONS } from '../../constants';
import { mapSessionStateToStore } from '../../utils/helper';
import { endVideoSession, leaveVideoSession } from '../../utils/endVideoSession';
import { ROOM_STATUS } from '../../utils/constants';

export default function useRoom() {
  const dispatch = useDispatch();
  const { accountId, clearState } = useJoinRoom();
  const { connect, disconnect } = useVideoContext();
  const joinerDetails = useSelector((store: RootState) => selectCreateRoomState(store));
  const sessionState = useSelector((store: RootState) => selectSessionState(store));
  const { roomId } = useSelector((store: RootState) => selectSessionState(store));
  const accountDetails = useSelector((store: RootState) => selectAccount(store));

  const inWaitingList = useMemo(() => {
    if (accountDetails.isHost) return false;

    if (accountId && !accountDetails.isHost && !sessionState.allowedParticipants.includes(accountId)) {
      return true;
    }
    return true;
  }, [accountDetails.isHost, accountId, sessionState.allowedParticipants]);

  const intiateSession = useCallback(async () => {
    try {
      let userId = accountDetails.userId;
      let userDetails = { ...accountDetails };

      dispatch(SET_LOADER({ userJoining: true }));
      dispatch(
        SET_ROOM_DETAILS({
          agreedTerms: true,
        })
      );

      // Create user if userID not found
      if (!userId) {
        const newUser: AccountDetails = await createUser({
          isHost: joinerDetails.userName === 'doctor',
          userName: joinerDetails.userName,
        });
        userId = newUser.userId;
        dispatch(
          SET_USER_DETAILS({
            userDetails: newUser.userDetails,
            userId: newUser.userId,
            userName: newUser.userName,
            isHost: newUser.isHost,
          })
        );

        userDetails = { ...newUser };
      }

      if (!userId) throw new Error('Failed to create User');

      // create room

      const roomDetails = await joinRoom({
        roomName: joinerDetails.roomName,
        roomType: joinerDetails.roomType,
        isHost: joinerDetails.userName === 'doctor',
        userId,
      });

      // add data to redux
      setSesssionState({
        roomId: roomDetails.roomId,
      });
      // ifHost then getToken and getIn the Room

      if (userDetails.isHost) {
        await getTokenAndConnect({ roomName: roomDetails.roomName, roomSize: roomDetails.roomType, userId });
      }

      dispatch(SET_LOADER({ userJoining: false }));
    } catch (error) {
      dispatch(SET_LOADER({ userJoining: false }));
      clearState();
    }
  }, [accountDetails.userId, joinerDetails]);

  const getTokenAndConnect = async ({ roomName, roomSize, userId }: GetToken) => {
    try {
      const { data } = await telehealthtoken({
        roomName,
        roomSize,
        userId,
      });
      dispatch(
        SET_USER_DETAILS({
          authToken: data.token,
          ipAddress: data.ipAddress,
        })
      );
      await connect(data.token);
      await updateIpOfUser({
        ipAddress: data.ipAddress,
        roomId: data.roomId,
        userId: userId,
      });
    } catch (error) {
      clearState();
      return;
    }
  };

  const clearUserPersistedState = () => {
    dispatch(
      SET_USER_DETAILS({
        ...initialState,
      })
    );
  };

  // set userDetail in createRoom state
  const setSesssionState = (params: Partial<SessionDetails>) => {
    dispatch(SET_SESSION_STATE(params));
  };

  const endSession = useCallback(() => {
    if (disconnect && roomId) {
      clearState(); // alows to goto first page
      disconnect();
      endVideoSession({
        roomId,
        roomName: sessionState.roomName,
        roomType: sessionState.roomType,
        activeParticipants: sessionState.activeParticipants,
        waitingParticipants: sessionState.waitingParticipants,
      });
    }
  }, [sessionState, disconnect]);

  // const setParticiapantsSnapShot = useCallback( async () => {
  //    await db.collection(COLLECTIONS.ACCOUNT).where("userId","in",sessionState.participants).onSnapshot(snap=>{
  //     const newParticipants = snap.docs.map(ele=>ele.data());
  //     dispatch(SET_SESSION_STATE({
  //       participantsDetails:newParticipants
  //     }));
  //   });
  // } , [sessionState.participants]);

  const leaveSession = useCallback(() => {
    if (disconnect && roomId && accountDetails.userId) {
      clearState(); // alows to goto first page
      disconnect();
      leaveVideoSession({
        roomId,
        userId: accountDetails.userId,
      });
    }
  }, [sessionState, disconnect]);

  useEffect(() => {
    if (roomId && roomId?.trim()?.length > 0)
      db.collection(COLLECTIONS.ROOM_DETAILS)
        .doc(roomId)
        .onSnapshot(snap => {
          if (snap.exists) {
            mapSessionSnapToStore(snap);
          }
        });
  }, [roomId]);

  const mapSessionSnapToStore = async (snap: any) => {
    try {
      const data = snap.data();
      const newSessionDetails = mapSessionStateToStore(data);

      let participantsDetails: any[] = [];

      if (newSessionDetails.participants.length > 0 && newSessionDetails.participants.length <= 10) {
        const participantsDetailsRes = await db
          .collection(COLLECTIONS.ACCOUNT)
          .where('userId', 'in', newSessionDetails?.participants || [])
          .get();
        participantsDetails = participantsDetailsRes.docs.map(ele => ele.data());
      }

      setSesssionState({
        ...newSessionDetails,
        participantsDetails,
      });

      if (newSessionDetails.status === ROOM_STATUS.ENDED) {
        clearState();
      }

      return;
    } catch (error) {
      if (error instanceof Error) console.log('Error', error.message);
      return;
    }
  };

  return {
    sessionState,
    setSesssionState,
    intiateSession,
    getTokenAndConnect,
    iAmHost: accountDetails.isHost,
    inWaitingList,
    clearUserPersistedState,
    accountDetails,
    roomId,
    endSession,
    leaveSession,
  };
}
