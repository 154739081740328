import { db } from '../../firebase/db.config';
import { COLLECTIONS } from '../../constants';

const getTelehealthDocRefs = () => {
  const roomDocRef = db.collection(COLLECTIONS.ROOM_DETAILS);
  const userDocRef = db.collection(COLLECTIONS.ACCOUNT);

  return {
    roomDocRef,
    userDocRef,
  };
};

export default getTelehealthDocRefs;
