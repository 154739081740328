import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import firebaseConfig from './config.js';
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const functions = firebaseApp.functions();

// db.auth().useEmulator("http://localhost:9099");

// functions.useFunctionsEmulator("http://localhost:5001");

export { db, functions };
