import React, { useState, useEffect, useRef, useMemo } from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import LeftColumn from '../LeftColumn/LeftColumn';
import { makeStyles, Theme } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TetrisIcon from '../../icons/TetrisIcon';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
// import Pusher from 'pusher-js/with-encryption';
import useSound from 'use-sound';
// import { axiosConfig, baseUrl, frontURL } from '../../axiosBaseURL';
import ToggleLeftButton from '../Buttons/ToggleLeftButton/ToggleLeftButton';
import ToggleRightButton from '../Buttons/ToggleRightButton/ToggleRightButton';
import ProfileBar from '../ProfileBar/ProfileBar';
import TetrisStart from '../TetrisStart/TetrisStart';
import ChatHeading from '../Chat/ChatHeading';
import ChatInput from '../Chat/ChatInput';
import ChatItem from '../Chat/ChatItem';
import Timer from '../Timer/Timer';
import ParticipantStatus from '../ParticipantStatus/ParticipantStatus';
import YoutubePlayer from '../YoutubePlayer/YoutubePlayer';
import ParticipantRequesting from '../ParticipantRequesting/ParticipantRequesting';
import InviteDialog from '../InviteDialog/InviteDialog';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { functions } from '../../firebase/db.config';
// import firebase from 'firebase';
import InviteIcon from '../../icons/InviteIcon';
// import { firebaseHelper } from '../../firebaseHelper';
import YoutubeDropdown from '../YoutubeDropdown/YoutubeDropdown';
import useRoom from '../../hooks/useRoom/useRoom';
import messageAudio from '../../sounds/message.wav';
import roomEnter from '../../sounds/roomEnter.wav';
import roomLeave from '../../sounds/roomLeave.wav';
import waitingAudio from '../../sounds/waiting.mp3';

import QRCode from 'qrcode.react';
import useAccount from '../../hooks/useAccount/useAccount';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    position: 'relative',
    height: '100%',
    [theme.breakpoints.down(750)]: {
      flexDirection: 'column',
    },
  },
  left: {
    width: '240px',
    minWidth: '240px',
    maxWidth: '240px',
    background: '#232325',
    padding: '3px',
    position: 'relative',
    fontFamily: 'arial',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'scroll',
    [theme.breakpoints.down(750)]: {
      position: 'absolute',
      width: '160px',
      height: '169px',
      minWidth: '160px',
      right: '2%',
      top: '10%',
      // zIndex: '9999',
      zIndex: '6',
      background: 'transparent',
      overflow: 'visible',
    },
  },
  middle: {
    background: '#232325',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
    [theme.breakpoints.down(750)]: {
      // height: '70vh',
      // maxHeight: '70vh',
    },
  },
  middle1: {
    [theme.breakpoints.down(750)]: {
      height: '70vh',
      maxHeight: '70vh',
    },
  },
  right: {
    maxWidth: '240px',
    minWidth: '240px',
    background: '#232325',
    minHeight: '50vh',
    maxHeight: '100vh',
    position: 'relative',
    fontFamily: 'arial',
    [theme.breakpoints.down(750)]: {
      zIndex: '9999',
      maxWidth: '100%',
    },
  },
  messageContainer: {
    background: '#2a2829',
    height: '81%',
    width: '98%',
    position: 'relative',
    left: '1%',
    overflow: 'scroll',
    [theme.breakpoints.down(750)]: {
      height: '38vh',
      position: 'relative',
      left: '1%',
      width: '98%',
      paddingBottom: '75px',
    },
  },
  hiddenDisconnect: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      width: '99%',
      // zIndex: '9999',
      marginTop: '8px',
    },
  },
  gameActive: {
    opacity: '1',
  },
  gameTriggerMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      textAlign: 'center',
      position: 'absolute',
      bottom: '3%',
      left: '2%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
      },
    },
  },
  gameTriggerMobile1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      position: 'relative',
      width: '60px',
      left: '1%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
        height: '50px',
      },
    },
  },
  counterMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#919192',
      textAlign: 'center',
      fontSize: '20px',
      padding: '2px',
    },
  },
  partStatus: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      flex: '1',
    },
  },
  partStatus1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  invite: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0 35px',
    '& svg': {
      fill: 'white',
    },
    '& .MuiSvgIcon-root': {
      height: '0.8em',
    },
  },
  activeInvite: {
    color: '#4284f3',
    '& svg': {
      fill: '#4284f3',
    },
  },
  invitationText: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      paddingTop: '15px',
    },
  },
  qrCode: {
    textAlign: 'center',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
  youtubeActive: {
    '& .MuiSvgIcon-root': {
      opacity: '1',
    },
  },
  youtubeTriggerMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      textAlign: 'center',
      position: 'absolute',
      bottom: '3%',
      left: '2%',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
      },
      '& .MuiSvgIcon-root': {
        fill: '#4284f3',
        fontSize: '6.5rem',
        opacity: '1',
        cursor: 'pointer',
      },
    },
  },
  youtubeTriggerMobile1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
      fill: '#4284f3',
      stroke: '#232325',
      position: 'relative',
      width: '60px',
      left: '1%',
      opacity: '1',
      cursor: 'pointer',
      '& svg': {
        width: '60px',
        height: '50px',
      },
      '& .MuiSvgIcon-root': {
        fill: '#4284f3',
        fontSize: '6.5rem',
        opacity: '1',
        cursor: 'pointer',
      },
    },
  },
  admitWarning: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      color: '#919192',
      padding: '0px 7px',
      display: 'block',
    },
  },
  maxWarning: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      color: 'red',
      display: 'block',
      textAlign: 'center',
    },
  },
  space: {
    padding: '4px',
  },
}));

interface ChatType {
  username: string;
  message: any;
  time: number;
}
const initialChats: Array<ChatType> = [];

export default function Room({
  visitor,
  handleTerms,
  pusherKey,
  pusherCluster,
}: {
  visitor?: string;
  handleTerms?: any;
  pusherKey?: any;
  pusherCluster?: any;
}) {
  const [leftbtn, setLeftbtn] = useState<boolean>(true);
  const [rightbtn, setRightbtn] = useState<boolean>(false);
  const [tetris, setTetris] = useState<boolean>(false);
  const [playYoutube, setPlayYoutube] = useState<boolean>(false);
  const [activeParticipantLength, setActiveParticipantLength] = useState<number>(0);
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const [playbackRate, setPlaybackRate] = useState(1);
  const { room } = useVideoContext();
  const classes = useStyles();
  const [chats, setChats] = useState(initialChats);
  const [totalChatsLength, setTotalChatsLength] = useState<number>(0);
  const [chatsLength, setChatsLength] = useState<number>(visitor === 'doctor' ? 1 : 0);
  const chatsFinal: Array<ChatType> = [];
  const date = new Date();
  var canPublish = true;
  var throttleTime = 200;
  var clearInterval1 = 900; //0.9 seconds
  var clearTimerId1: any;
  const time = Timer();
  const posRightBtn = tetris || playYoutube;
  const [text, setText] = useState<string>('');
  const [typing, setTyping] = useState<string>('');
  const [play] = useSound(messageAudio, {
    playbackRate,
    volume: 1,
  });
  const [invitePopupOpen, setInvitePopupOpen] = useState<boolean>(false);
  const [waitingParticipantsLength, setWaitingParticipantsLength] = useState<number>(0);
  const [invitationsSent, setInvitationsSent] = useState<number>(0);
  const [roomType, setRoomType] = useState<string>();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const [currentParticipantLength, setCurrentParticipantLength] = useState<number>(0);
  const [allowedParticipantLength, setAllowedParticipantLength] = useState<number>(0);
  const [activeParticipant, setActiveParticipant] = useState([]);
  const [playListId, setPlayListId] = useState<string>();
  const { userId } = useAccount();
  const [numberOfInvitationSend, setNumberOfInvitationSend] = useState<number>(0);
  var roomEnterAudio = new Audio(roomEnter);
  var roomLeaveAudio = new Audio(roomLeave);
  var waitingaudio = new Audio(waitingAudio);

  const handleChats = async (data: ChatType) => {
    setPlaybackRate(playbackRate);
    play();
    chatsFinal.push(data);
    setTotalChatsLength(chatsFinal.length);
    setChats(chatsFinal);
  };
  const handleLeftBtn = () => {
    setLeftbtn(!leftbtn);
  };
  const handleRightBtn = () => {
    setChatsLength(totalChatsLength);
    setRightbtn(!rightbtn);
  };
  const handleTetrisIcon = () => {
    if (activeParticipantLength === 0) {
      setPlayYoutube(false);
      setTetris(!tetris);
    } else setTetris(false);
  };
  const handlePlayYoutube = () => {
    if (activeParticipantLength === 0) {
      setTetris(false);
      setPlayYoutube(!playYoutube);
    } else setPlayYoutube(false);
  };
  const handleActiveParticipant = (len: number) => {
    setActiveParticipantLength(len);
  };

  const handleSubmit = async (customText: string | undefined) => {
    console.log('customText', customText);
    var time = date.getUTCHours() * 60 + date.getUTCMinutes();
    const payload = {
      username: visitor,
      message: customText ? customText : text,
      time,
    };
    if (payload.message.length !== 0) {
      setText('');
      if (chats.length || visitor !== 'doctor') {
        setPlaybackRate(playbackRate);
        play();
      }
      const telehealthmessage = functions.httpsCallable('telehealthmessage');
      var channel = room.name;
      telehealthmessage({ channel, payload });
      // axiosConfig
      //   .post(`/telehealthmessage?channelName=${room.name}`, payload)
      //   .then(response => {
      //     return response;
      //   })
      //   .catch(function(error) {
      //     console.log('error ----', error);
      //   });
    }
  };
  const handleEnter = (e: any) => {
    if (e.charCode === 13) {
      handleSubmit('');
    }
  };
  const handleYoutubePlaylists = (playlist: any) => {
    setPlayListId(playlist);
  };
  const handleTextChange = (e: any) => {
    const letterNumber = /^[ a-zA-Z0-9`~!@#$%^&*()_+|\-=\\{}\[\]:"";'<>?,./]*$/;
    if (e.target.value.match(letterNumber)) setText(e.target.value);
    else if (e.target.value.match('')) setText('');
    // setText(e.target.value)
    const payload = {
      username: visitor,
    };
    if (canPublish) {
      const usertyping = functions.httpsCallable('usertyping');
      var channel = room.name;
      usertyping({ channel, payload });
      // axiosConfig
      //   .post(`/usertyping?channelName=${room.name}`, payload)
      //   .then(response => {
      //     return response;
      //   })
      //   .catch(function(error) {
      //     console.log('error ----', error);
      //   });
      canPublish = false;
      setTimeout(function() {
        canPublish = true;
      }, throttleTime);
    }
  };
  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };
  // const handleDimensions = () => {
  //   if (window.innerWidth > 749) {
  //     setLeftbtn(true);
  //   }
  //   if (window.innerWidth < 749) {
  //     setLeftbtn(true);
  //   }
  // };
  useEffect(() => {
    roomEnterAudio.play();
    var admitBy = '';

    // handleDimensions();
    // window.addEventListener('resize', handleDimensions);
    // const pusher = new Pusher(pusherKey, {
    //   cluster: pusherCluster,
    //   // authEndpoint: baseUrl + '/pusher/auth',
    //   authTransport: 'jsonp',
    //   authEndpoint: baseUrl + '/pusherauth',
    // });
    // var channel = pusher.subscribe('private-encrypted-' + room.name);
    // channel &&
    //   channel.bind('message', (data: any) => {
    //     handleChats(data);
    //   });
    // channel &&
    //   channel.bind('user_typing', function(data: any) {
    //     if (data.username !== visitor) {
    //       var typingText = data.username + ' is typing...';
    //       setTyping(typingText);
    //       clearTimeout(clearTimerId1);
    //       clearTimerId1 = setTimeout(function() {
    //         setTyping('');
    //       }, clearInterval1);
    //     }
    //   });

    // return () => {
    //   window.onunload = window.onbeforeunload = null;
    //   roomLeaveAudio.play();
    //   // pusher.unsubscribe('private-encrypted-' + room.name);
    //   // window.removeEventListener('resize', handleDimensions);
    //   if(!roomId)
    //   return;

    //   db.collection('roomDetails')
    //     .doc(roomId)
    //     .update({
    //       activeParticipants: firebase.firestore.FieldValue.arrayRemove(accountDetails.userId),
    //     });
    // };
  }, []);

  useEffect(() => {
    if (currentParticipantLength > 1) {
      setPlayYoutube(false);
      setTetris(false);
    }
  }, [currentParticipantLength]);

  useEffect(() => {
    scrollToBottom();
  }, [chats, totalChatsLength, chatsFinal]);
  useEffect(() => {
    // play();
  }, [chats]);
  useEffect(() => {
    if (visitor === 'doctor') {
      if (waitingParticipantsLength > 0) {
        waitingaudio.load();
        waitingaudio.play();
        if (!leftbtn) {
          setLeftbtn(true);
        }
        if (playYoutube) {
          setPlayYoutube(false);
        }
        if (tetris) {
          setTetris(false);
        }
      } else if (currentParticipantLength - 1 > waitingParticipantsLength && allowedParticipantLength > 1) {
        roomEnterAudio.load();
        roomEnterAudio.play();
      } else if (currentParticipantLength < allowedParticipantLength) {
        roomLeaveAudio.load();
        roomLeaveAudio.play();
      }
    }
  }, [waitingParticipantsLength, currentParticipantLength, allowedParticipantLength]);
  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.hiddenDisconnect}>
          <EndCallButton handleTerms={handleTerms} roomName={room.name} visitor={visitor} />
        </div>
        {leftbtn && (
          <div className={classes.left}>
            <LeftColumn
              visitor={visitor}
              handleLeftBtn={handleLeftBtn}
              isReconnecting={isReconnecting}
              isSharingScreen={isSharingScreen}
              tetris={tetris}
              toggleScreenShare={toggleScreenShare}
              roomName={room.name}
              activeParticipantLength={activeParticipantLength}
              activeParticipant={activeParticipant}
              handleTetrisIcon={handleTetrisIcon}
              time={time}
              handleTerms={handleTerms}
              handlePlayYoutube={handlePlayYoutube}
              playYoutube={playYoutube}
              invitationsSent={invitationsSent}
              roomType={roomType}
              currentParticipantLength={currentParticipantLength}
              handleYoutubePlaylists={handleYoutubePlaylists}
              waitingParticipantsLength={waitingParticipantsLength}
            />
          </div>
        )}
        {playYoutube && (
          <div className={classes.partStatus1}>
            <YoutubeDropdown handleYoutubePlaylists={handleYoutubePlaylists} />
          </div>
        )}
        <div className={tetris ? classes.middle : `${classes.middle} ${classes.middle1}`}>
          {activeParticipantLength === 0 ? (
            <>
              {tetris && <TetrisStart roomName={room.name} visitor={visitor} />}
              {playYoutube && <YoutubePlayer playListId={playListId} roomName={room.name} visitor={visitor} />}
              {!tetris && !playYoutube && (
                <ParticipantList
                  handleTetrisIcon={handleTetrisIcon}
                  handleActiveParticipant={handleActiveParticipant}
                  isSharingScreen={isSharingScreen}
                  handlePlayYoutube={handlePlayYoutube}
                  waitingParticipantsLength={waitingParticipantsLength}
                />
              )}
            </>
          ) : (
            <>
              <ParticipantList
                handleTetrisIcon={handleTetrisIcon}
                handleActiveParticipant={handleActiveParticipant}
                isSharingScreen={isSharingScreen}
                handlePlayYoutube={handlePlayYoutube}
                waitingParticipantsLength={waitingParticipantsLength}
              />
            </>
          )}
        </div>
        {!leftbtn && !tetris && !playYoutube ? (
          <>
            <ProfileBar
              visitor={visitor}
              isReconnecting={isReconnecting}
              handleLeftBtn={handleLeftBtn}
              roomName={room.name}
              handleTerms={handleTerms}
              roomType={roomType}
              activeParticipantLength={currentParticipantLength}
              waitingParticipantsLength={waitingParticipantsLength}
            />
            <div className={classes.partStatus}>
              {visitor === 'doctor' && (
                <>
                  <ParticipantRequesting
                    visitor={visitor}
                    roomType={roomType}
                    roomName={room.name}
                    currentParticipantsLength={currentParticipantLength}
                  />
                  {invitationsSent > 0 && (
                    <div className={classes.admitWarning}>
                      By admitting invitees into this session, I am authorizing them to participate in this conversation
                      about the patient’s Protected Health Information
                    </div>
                  )}
                </>
              )}
              {visitor === 'doctor' && roomType === 'small' ? (
                currentParticipantLength === 2 ? (
                  <div className={classes.maxWarning}>
                    <p>Max 2 users per session</p>
                    <p>Max has been reached; no additional users may be admitted to the session</p>
                  </div>
                ) : visitor === 'doctor' && invitationsSent > 0 && currentParticipantLength > 1 ? (
                  <div className={classes.maxWarning}>Max 2 users per session</div>
                ) : (
                  <div></div>
                )
              ) : currentParticipantLength === 50 ? (
                <div className={classes.maxWarning}>
                  <p>Max 50 users per session</p>
                  <p>Max has been reached; no additional users may be admitted to the session</p>
                </div>
              ) : visitor === 'doctor' && invitationsSent > 0 && currentParticipantLength > 1 ? (
                <div className={classes.maxWarning}>Max 50 users per session</div>
              ) : (
                <div></div>
              )}
            </div>
          </>
        ) : (
          <div className={classes.partStatus}>
            <ParticipantStatus
              roomName={room.name}
              handleTerms={handleTerms}
              roomType={roomType}
              visitor={visitor}
              waitingParticipantsLength={waitingParticipantsLength}
            />
            {visitor === 'doctor' && (
              <>
                <ParticipantRequesting
                  visitor={visitor}
                  roomName={room.name}
                  roomType={roomType}
                  currentParticipantsLength={currentParticipantLength}
                />
                {invitationsSent > 0 && (
                  <div className={classes.admitWarning}>
                    By admitting invitees into this session, I am authorizing them to participate in this conversation
                    about the patient’s Protected Health Information
                  </div>
                )}
              </>
            )}
            {visitor === 'doctor' && currentParticipantLength === 50 ? (
              <div className={classes.maxWarning}>
                <p>Max 50 users per session</p>
                <p>Max has been reached; no additional users may be admitted to the session</p>
              </div>
            ) : visitor === 'doctor' && invitationsSent > 0 && currentParticipantLength > 1 ? (
              <div className={classes.maxWarning}>Max 50 users per session</div>
            ) : (
              <div></div>
            )}
          </div>
        )}
        {activeParticipantLength === 0 && !leftbtn && !tetris && !playYoutube ? (
          <div className={classes.counterMobile}>
            <AccessTimeIcon />
            <div className={classes.space} />
            {time}
          </div>
        ) : (
          <div className={classes.counterMobile}>
            <AccessTimeIcon />
            <div className={classes.space} />
            {time}
          </div>
        )}
        {!tetris && !playYoutube && visitor === 'doctor' && (
          <div className={classes.invitationText}>
            <span
              className={!invitePopupOpen ? classes.invite : `${classes.invite} ${classes.activeInvite}`}
              onClick={() => setInvitePopupOpen(true)}
            >
              <div>
                <InviteIcon />
              </div>
              {roomType === 'large' ? (
                <div style={{ paddingLeft: '5px', paddingBottom: '10px' }}>
                  TeleShare<sup style={{ fontSize: '7px' }}>TM</sup> Invite
                </div>
              ) : (
                <div style={{ paddingLeft: '5px', paddingBottom: '10px' }}>TeleHealth Invite</div>
              )}
            </span>
            {roomType === 'large' && (
              <div className={classes.qrCode} onClick={() => setInvitePopupOpen(true)}>
                <QRCode
                  value={window.location.origin + `/sendInvitation/${room.name}/${roomType}`}
                  size={150}
                  includeMargin={true}
                />
              </div>
            )}
          </div>
        )}
        {playYoutube ? (
          rightbtn ? (
            <div className={`${classes.youtubeTriggerMobile1} ${classes.youtubeActive}`} onClick={handlePlayYoutube}>
              <YouTubeIcon />
            </div>
          ) : (
            <div className={`${classes.youtubeTriggerMobile} ${classes.youtubeActive}`} onClick={handlePlayYoutube}>
              <YouTubeIcon />
            </div>
          )
        ) : (
          <div></div>
        )}
        {tetris ? (
          rightbtn ? (
            <div className={`${classes.gameTriggerMobile1} ${classes.gameActive}`} onClick={handleTetrisIcon}>
              <TetrisIcon />
            </div>
          ) : (
            <div className={`${classes.gameTriggerMobile} ${classes.gameActive}`} onClick={handleTetrisIcon}>
              <TetrisIcon />
            </div>
          )
        ) : (
          <div></div>
        )}
        {rightbtn && (
          <div className={classes.right}>
            <ChatHeading handleRightBtn={handleRightBtn} />
            <div className={classes.messageContainer}>
              {chats.map((item: ChatType, index: number) => {
                if (visitor === 'doctor' && index === 0) {
                  return '';
                }
                return <ChatItem key={index} item={item} />;
              })}
              <div ref={messagesEndRef} />
            </div>
            <ChatInput
              text={text}
              typing={typing}
              handleEnter={handleEnter}
              handleTextChange={handleTextChange}
              handleSubmit={() => handleSubmit(text)}
            />
          </div>
        )}
        {!leftbtn && (
          <ToggleLeftButton visitor={visitor} handleLeftBtn={handleLeftBtn} active={waitingParticipantsLength} />
        )}
        {!rightbtn && (
          <ToggleRightButton
            totalChatsLength={totalChatsLength}
            chatsLength={chatsLength}
            handleRightBtn={handleRightBtn}
            posRightBtn={posRightBtn}
          />
        )}
      </div>
      <InviteDialog
        open={invitePopupOpen}
        roomName={room.name}
        visitor={visitor}
        roomType={roomType}
        numberOfInvitationSend={numberOfInvitationSend}
        onClose={() => {
          setNumberOfInvitationSend(number => number + 1);
          setInvitePopupOpen(false);
        }}
      />
    </>
  );
}
