// eslint-disable

import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';
import PreJoinScreens from './components/PreJoinScreens/PreJoinScreens';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import Room from './components/Room/Room';
import { makeStyles } from '@material-ui/core';
import useHeight from './hooks/useHeight/useHeight';
import useRoomState from './hooks/useRoomState/useRoomState';
import PHIConsent from './components/PHIConsent/PHIConsent';
// import { useAppState } from './state';
// import useVideoContext from './hooks/useVideoContext/useVideoContext';
import WaitingRoom from './components/WaitingRoom/WaitingRoom';
import { getCredentials } from './firebaseHelper';
import './styles/bootstrap.min.css';
import useJoinRoom from './components/VideoProvider/useJoinRoom/useJoinRoom';
import { RoomTypes } from './utils/documentModals/getRoomModel';
import { ROOM_STATUS, ROOM_TYPE } from './utils/constants';
import useRoom from './hooks/useRoom/useRoom';
import TestLayout from './components/VideoLayouts/TestLayout';
// import {VideoLayout} from './components/VideoLayouts'
import './styles/styles.css';

// import { tokenResponse } from './types';
// import userDetails from './store/userDetails';
const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    background: '#232325',
    // height:'100vh'
  },
}));

export default function App() {
  const roomState = useRoomState();
  const classes = useStyles();
  const [visitor, setVisitor] = useState<string>('');
  const [termsAccepted, setTermsAccepted] = useState<boolean>();
  // const [showWaitingRoom, setShowWaitingRoom] = useState<boolean>(false);

  const [rnm, setrnm] = useState<string>('');
  const { isUserJoining, joinerDetails } = useJoinRoom();
  const { intiateSession, inWaitingList, sessionState } = useRoom();
  const [pusherKey, setPusherKey] = useState<string>('');
  const [pusherCluster, setPusherCluster] = useState<string>('');
  const [roomType] = useState<RoomTypes>(ROOM_TYPE.SMALL);
  // const {  isFetching } = useAppState();
  // const {  isAcquiringLocalTracks, isConnecting } = useVideoContext();
  // const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  useEffect(() => {
    getCredentials().then((res: any) => {
      setPusherKey(res.pusherKey);
      setPusherCluster(res.pusherCluster);
    });
  }, []);

  const handleTerms = (name?: any, room?: any) => {
    if (name || room) {
      // name && setnm(name);
      name && setVisitor(name);
      room && setrnm(room);
      setTermsAccepted(!termsAccepted);
      // setShowWaitingRoom(false);
    } else {
      setTermsAccepted(false);
    }
  };

  // const callGetToken = () => {
  //   getToken(joinerName, rnm).then(({ roomName, token, userId }) => {
  //     connect(token)
  //       .then(() => {
  //         setShowWaitingRoom(false);
  //         setTermsAccepted(false);
  //       })
  //       .catch(error => console.log('failure', error));
  //   });
  // };

  const fetchToken = async () => {
    try {
      await intiateSession();
      // setShowWaitingRoom(true);
    } catch (error) {
      console.log('ERROR fetchToken ', error);
    }
  };

  const height = useHeight();

  switch (true) {
    case roomState === 'connected' && sessionState.status !== ROOM_STATUS.ENDED:
      return (
        <Container style={{ height }}>
          <div className={classes.wrapper}>
            <ReconnectingNotification />
            <Room visitor={visitor} handleTerms={handleTerms} pusherKey={pusherKey} pusherCluster={pusherCluster} />
          </div>
        </Container>
      );
    case joinerDetails.userName.trim() === '' || joinerDetails.roomName.trim() === '' || !joinerDetails.devicesSelected:
      return (
        <Container style={{ height }}>
          <PreJoinScreens setVisitor={setVisitor} handleTerms={handleTerms} roomType={roomType} />
        </Container>
      );
    case isUserJoining || !joinerDetails.agreedTerms:
      return (
        <Container style={{ height }}>
          <PHIConsent fetchToken={fetchToken} disableBtn={isUserJoining} />
        </Container>
      );
    case inWaitingList:
      return (
        <Container style={{ height }}>
          <WaitingRoom visitor={joinerDetails.roomName} handleTerms={handleTerms} roomname={rnm} />
        </Container>
      );

    default:
      return <Container style={{ height }}></Container>;
  }
}
