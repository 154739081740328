// eslint-disable-next-line @typescript-eslint/no-redeclare
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateRoom } from './types';
import { RootState } from './store';
import { db } from '../firebase/db.config';
import { joinWaitingList } from '../utils/joinToWaitingList';
import firebase from 'firebase';
import { Loaders } from './types';

const initialState: Loaders = {
  isCreatingUser: false,
  userJoining: false,
};

// middlewares
// export const CREATE_ROOM = createAsyncThunk(
//   "createRoom/CREATE_ROOM",
//   async (params, {getState}) => {
//     try {
//       const store :  ReturnType<typeof getState> = getState();
//       const {roomName, userName} = selectCreateRoomState(store as RootState);

//       const res = await db
//         .collection('roomDetails')
//         .where('roomName', '==', roomName)
//         .get();
//       var clientDate = new Date();
//       var currentDate = clientDate.getDate();
//       var currentMonth = clientDate.getMonth();
//       var idDocAdded : string | null =null;
//       var createNewDocument;
//       var id, exists, docDate, docMonth;
//       res.docs.forEach(item => {
//         id = item.id;
//         exists = item.exists;
//         docDate = item
//           .data()
//           .timeStamp.toDate()
//           .getDate();
//         docMonth = item
//           .data()
//           .timeStamp.toDate()
//           .getMonth();
//         if (exists && currentDate === docDate && currentMonth === docMonth) {
//           idDocAdded = id;
//           createNewDocument = false;
//         }
//       });

//       if (createNewDocument === false) {
//       } else {
//         const newRoom = await db.collection('roomDetails').add({
//           roomName: roomName,
//           timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
//         });
//         idDocAdded = newRoom.id;
//       }
//       if (userName === 'doctor') {
//         getToken(userName, roomName).then(token => {
//           connect(token);
//         });
//         await db
//           .collection('roomDetails')
//           .doc(idDocAdded || undefined)
//           .update({
//             hostName: userName,
//             roomType,
//           });
//         // setShowWaitingRoom(false);
//       } else {
//         await joinWaitingList({participantName:userName ,roomId:idDocAdded})
//         // setShowWaitingRoom(true);
//       }
//     } catch (error) {
//       console.log('ERROR fetchToken ', error);
//     }
//   }
//     )

export const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    SET_LOADER: (state, { payload }: PayloadAction<Partial<Loaders>>) => {
      state.isCreatingUser =
        typeof payload.isCreatingUser === 'boolean' ? payload.isCreatingUser : state.isCreatingUser;
      state.userJoining = typeof payload.userJoining === 'boolean' ? payload.userJoining : state.userJoining;
    },
  },
  extraReducers: {
    // [CREATE_ROOM.pending as any]:()=>{
    // }
  },
});

// Action creators are generated for each case reducer function
export const { SET_LOADER } = loaderSlice.actions;

export default loaderSlice.reducer;

// selectors
export const selectLoaders = (store: RootState) => store.loaders;
