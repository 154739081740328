import React, { useState } from 'react';
import { makeStyles, Theme, Button, Typography, Divider } from '@material-ui/core';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import SettingsMenu from '../PreJoinScreens/DeviceSelectionScreen/SettingsMenu/SettingsMenu';
import TetrisIcon from '../../icons/TetrisIcon';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import LocalVideoPreview from '../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import ParticipantStatus from '../ParticipantStatus/ParticipantStatus';
import ParticipantRequesting from '../ParticipantRequesting/ParticipantRequesting';
import InviteDialog from '../InviteDialog/InviteDialog';
import YouTubeIcon from '@material-ui/icons/YouTube';
import { frontURL } from '../../axiosBaseURL';
import InviteIcon from '../../icons/InviteIcon';
import YoutubeDropdown from '../YoutubeDropdown/YoutubeDropdown';
import ChevronDown from '../../icons/ChevronDown';
import QRCode from 'qrcode.react';

const useStyles = makeStyles((theme: Theme) => ({
  camera: {
    minHeight: '133px',
    border: '2px solid rgb(50, 49, 50)',
    zIndex: 9999,
    [theme.breakpoints.down(750)]: {
      minHeight: '96px',
      maxHeight: '96px',
      border: 'none',
    },
  },
  topHeading: {
    height: '40px',
    backgroundColor: '#323132',
    outline: 'none',
    padding: '7px',
    color: 'white',
    fontSize: '18px',
    borderRadius: '10px 10px 0px 0px',
    border: '2px solid rgb(50, 49, 50)',
    position: 'relative',
    [theme.breakpoints.down(750)]: {
      left: '0%',
      zIndex: '1',
      border: 'none',
      marginTop: '-4px',
      backgroundColor: 'rgb(0,0,0,0.5)',
      borderRadius: '0px',
    },
  },
  topIconSpace: {
    float: 'right',
    cursor: 'pointer',
  },
  controller: {
    background: '#18181a',
    marginTop: '-1px',
    // borderRadius: '0px 0px 10px 10px ',
    [theme.breakpoints.down(750)]: {
      marginTop: '-5px',
      borderRadius: '0px',
      backgroundColor: 'rgb(0,0,0,0.7)',
      position: 'relative',
    },
  },
  controller1: {
    background: '#18181a',
    marginTop: '-1px',
    // borderRadius: '0px 0px 10px 10px ',
    [theme.breakpoints.down(750)]: {
      marginTop: '-6px',
    },
  },
  controls: {
    padding: '0 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    [theme.breakpoints.down(750)]: {
      padding: '0px',
    },
    // '& .MuiButton-root': {
    //   minWidth: '20px',
    //   width: '100%',
    // },
  },
  shareScreen: {
    color: 'white',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  gameTrigger: {
    fill: '#4284f3',
    stroke: '#232325',
    textAlign: 'center',
    // position: 'absolute',
    // left: '30%',
    // bottom: '91px',
    opacity: '0.25',
    cursor: 'pointer',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  disconnect: {
    // position: 'absolute',
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  screenShareBanner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    '& h6': {
      color: 'white',
      fontSize: '14px',
    },
    '& button': {
      background: '#4284f3',
      color: 'white',
      margin: '1em 2em 0em 2em',
      height: '25px',
      padding: '0.8em',
      '&:hover': {
        background: 'red',
      },
    },
  },
  screenShareContainer: {
    background: '#18181a',
    // borderRadius: '0px 0px 10px 10px',
    height: '136px',
    width: '100%',
    // boxShadow: '1px 2px 3px rgb(50, 49, 50)',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  shareIcon: {
    color: 'white',
  },
  gameActive: {
    opacity: '1',
  },
  tetrisActiveLeft: {
    display: 'block',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  tetrisLeft: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  counter: {
    color: '#919192',
    textAlign: 'center',
    fontSize: '18px',
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  counter1: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2px',
    color: '#919192',
    textAlign: 'center',
    fontSize: '22px',
    // flex: '1',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  space: {
    padding: '0 4px',
  },
  flex: {
    display: 'block',
    flex: '1',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  partStatus: {
    display: 'block',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  invite: {
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
    margin: '0 35px',
    '& .MuiSvgIcon-root': {
      height: '0.8em',
    },
    '& svg': {
      fill: 'white',
    },
  },
  activeInvite: {
    color: '#4284f3',
    '& svg': {
      fill: '#4284f3',
    },
  },
  invitationText: {
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  youtubeTrigger: {
    textAlign: 'center',
    '& .MuiSvgIcon-root': {
      fill: '#4284f3',
      fontSize: '6.5rem',
      opacity: '0.25',
      cursor: 'pointer',
    },
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  youtubeActive: {
    '& .MuiSvgIcon-root': {
      opacity: '1',
    },
  },
  qrCode: {
    textAlign: 'center',
    paddingBottom: '5px',
    cursor: 'pointer',
  },
  border: {
    background: '#18181a',
    borderRadius: '10px',
    [theme.breakpoints.up(750)]: {
      paddingBottom: '6px',
    },
  },
  admitWarning: {
    color: '#919192',
    padding: '0px 7px',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  maxWarning: {
    color: 'red',
    textAlign: 'center',
    // flex: '1',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  youtubeDropdown: {
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  audio: {
    padding: '4px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingLeft: '0px',
    margin: '0',
    '& button': {
      justifyContent: 'center',
      margin: '0',
      padding: '0',
    },
    '& span': {
      margin: '0',
      padding: '0',
    },
    [theme.breakpoints.up(750)]: {
      // paddingLeft: '12px',
      // width: '68px',
    },
  },
  video: {
    padding: '4px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '0',
    '& button': {
      justifyContent: 'center',
      margin: '0',
      padding: '0',
    },
    '& span': {
      margin: '0',
      padding: '0',
    },
    [theme.breakpoints.up(750)]: {
      // paddingLeft:'28px',
    },
  },
  settings: {
    padding: '4px 0',
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    margin: '0',
    '& button': {
      justifyContent: 'center',
      margin: '0',
      padding: '0',
    },
    '& span': {
      margin: '0',
      padding: '0',
    },
    [theme.breakpoints.up(750)]: {
      // paddingLeft: '4px',
      // paddingRight: '9px',
    },
  },
}));
interface LeftParameters {
  visitor?: any;
  handleLeftBtn?: any;
  isReconnecting?: any;
  isSharingScreen?: any;
  tetris?: any;
  toggleScreenShare?: any;
  roomName?: any;
  activeParticipantLength?: any;
  handleTetrisIcon?: any;
  time: any;
  handleTerms?: any;
  handlePlayYoutube?: any;
  playYoutube?: boolean;
  showOnlyWaitingRoom?: boolean;
  invitationsSent?: any;
  roomType?: any;
  currentParticipantLength?: any;
  handleYoutubePlaylists?: any;
  waitingParticipantsLength?: any;
  activeParticipant?: any;
}
interface LeftContainerParameters {
  visitor?: any;
  handleLeftBtn?: any;
  isReconnecting?: any;
  isSharingScreen?: any;
}
const LeftContainer = ({ visitor, handleLeftBtn, isReconnecting, isSharingScreen }: LeftContainerParameters) => {
  const classes = useStyles();
  return (
    <>
      <div className={classes.topHeading}>
        {visitor}
        <span onClick={handleLeftBtn} className={classes.topIconSpace}>
          {/* <KeyboardArrowDownIcon /> */}
          <ChevronDown />
        </span>{' '}
      </div>
      <div className={classes.camera}>
        {' '}
        <LocalVideoPreview identity={''} />
      </div>
      <div className={!isSharingScreen ? classes.controller : classes.controller1}>
        <div className={classes.controls}>
          <div className={classes.audio}>
            <ToggleAudioButton hideText disabled={isReconnecting} />
          </div>
          <div className={classes.video}>
            <ToggleVideoButton hideText disabled={isReconnecting} />
          </div>
          <div className={classes.settings}>
            <SettingsMenu disableAudioVideo={false} evisit />
          </div>
        </div>
        <div className={classes.shareScreen}>
          {!isSharingScreen && <ToggleScreenShareButton disabled={isReconnecting || isSharingScreen} />}
        </div>
      </div>
    </>
  );
};
export default function LeftColumn({
  visitor,
  handleLeftBtn,
  isReconnecting,
  isSharingScreen,
  tetris,
  toggleScreenShare,
  roomName,
  activeParticipantLength,
  handleTetrisIcon,
  time,
  handleTerms,
  handlePlayYoutube,
  playYoutube,
  showOnlyWaitingRoom,
  invitationsSent,
  roomType,
  currentParticipantLength,
  handleYoutubePlaylists,
  waitingParticipantsLength,
  activeParticipant,
}: LeftParameters) {
  const classes = useStyles();
  const [invitePopupOpen, setInvitePopupOpen] = useState<boolean>(false);
  const [numberOfInvitationSend, setNumberOfInvitationSend] = useState<number>(0);
  return (
    <>
      <div className={classes.border}>
        <div className={classes.tetrisActiveLeft}>
          <LeftContainer
            visitor={visitor}
            handleLeftBtn={handleLeftBtn}
            isReconnecting={isReconnecting}
            isSharingScreen={isSharingScreen}
          />
        </div>
        <div className={classes.tetrisLeft}>
          {!tetris && !playYoutube && (
            <>
              <LeftContainer
                visitor={visitor}
                handleLeftBtn={handleLeftBtn}
                isReconnecting={isReconnecting}
                isSharingScreen={isSharingScreen}
              />
            </>
          )}
        </div>
        {isSharingScreen && (
          <div className={classes.screenShareContainer}>
            {/* <ToggleScreenShareButton disabled={isReconnecting || isSharingScreen} /> */}
            <Divider light />
            <div className={classes.screenShareBanner}>
              <div>
                <PresentToAllIcon className={classes.shareIcon} />
              </div>
              <div>
                <Typography variant="h6">You are sharing your screen</Typography>
              </div>
              <div>
                <Button onClick={() => toggleScreenShare()}>Stop Sharing</Button>
              </div>
            </div>
          </div>
        )}
        {!showOnlyWaitingRoom ? (
          <>
            <div className={classes.partStatus}>
              <ParticipantStatus
                roomName={roomName}
                handleTerms={handleTerms}
                roomType={roomType}
                visitor={visitor}
                waitingParticipantsLength={waitingParticipantsLength}
              />
            </div>
            {visitor === 'doctor' && (
              <div className={classes.partStatus}>
                <ParticipantRequesting
                  visitor={visitor}
                  roomType={roomType}
                  roomName={roomName}
                  currentParticipantsLength={currentParticipantLength}
                  activeParticipant={activeParticipant}
                  activeParticipantLength={activeParticipantLength}
                />
              </div>
            )}
          </>
        ) : (
          <></>
        )}
      </div>
      <div className={classes.counter1}>
        <AccessTimeIcon />
        <div className={classes.space} />
        {time}
      </div>
      {visitor === 'doctor' && (invitationsSent > 0 || Boolean(waitingParticipantsLength)) && (
        <div className={classes.admitWarning}>
          By admitting invitees into this session, I am authorizing them to participate in this conversation about the
          patient’s Protected Health Information
        </div>
      )}
      {visitor === 'doctor' && roomType === 'small' ? (
        currentParticipantLength === 2 ? (
          <div className={classes.maxWarning}>
            <p>Max 2 users per session</p>
            <p>Max has been reached; no additional users may be admitted to the session</p>
          </div>
        ) : visitor === 'doctor' && invitationsSent > 0 && currentParticipantLength > 1 ? (
          <div className={classes.maxWarning}>Max 2 users per session</div>
        ) : (
          <div></div>
        )
      ) : currentParticipantLength === 50 ? (
        <div className={classes.maxWarning}>
          <p>Max 50 users per session</p>
          <p>Max has been reached; no additional users may be admitted to the session</p>
        </div>
      ) : visitor === 'doctor' && invitationsSent > 0 && currentParticipantLength > 1 ? (
        <div className={classes.maxWarning}>Max 50 users per session</div>
      ) : (
        <div></div>
      )}
      {playYoutube && (
        <div className={classes.youtubeDropdown}>
          <YoutubeDropdown handleYoutubePlaylists={handleYoutubePlaylists} />
        </div>
      )}
      <div className={classes.flex}></div>
      {/* {!tetris && !playYoutube && <div className={classes.counter}>{time}</div>} */}
      <div>
        {activeParticipantLength !== 0 ? (
          ''
        ) : playYoutube ? (
          <div className={`${classes.youtubeTrigger} ${classes.youtubeActive}`} onClick={handlePlayYoutube}>
            <YouTubeIcon />
          </div>
        ) : (
          <div className={classes.youtubeTrigger} onClick={handlePlayYoutube}>
            <YouTubeIcon />
          </div>
        )}
        {activeParticipantLength !== 0 ? (
          ''
        ) : tetris ? (
          <div className={`${classes.gameTrigger} ${classes.gameActive}`} onClick={handleTetrisIcon}>
            <TetrisIcon />
          </div>
        ) : (
          <div className={classes.gameTrigger} onClick={handleTetrisIcon}>
            <TetrisIcon />
          </div>
        )}
        {!showOnlyWaitingRoom ? (
          visitor === 'doctor' && (
            <>
              <div className={classes.invitationText}>
                <span
                  className={!invitePopupOpen ? classes.invite : `${classes.invite} ${classes.activeInvite}`}
                  onClick={() => setInvitePopupOpen(true)}
                >
                  <div>
                    <InviteIcon />
                  </div>
                  {roomType === 'large' ? (
                    <div style={{ paddingLeft: '5px', paddingBottom: '10px' }}>
                      TeleShare<sup style={{ fontSize: '7px' }}>TM</sup> Invite
                    </div>
                  ) : (
                    <div style={{ paddingLeft: '5px', paddingBottom: '10px' }}>TeleHealth Invite</div>
                  )}
                </span>
                {roomType === 'large' && (
                  <div className={classes.qrCode} onClick={() => setInvitePopupOpen(true)}>
                    <QRCode
                      value={window.location.origin + `/sendInvitation/${roomName}/${roomType}`}
                      size={150}
                      includeMargin={true}
                    />
                  </div>
                )}
              </div>
            </>
          )
        ) : (
          <></>
        )}
        <div className={classes.disconnect}>
          <EndCallButton
            handleTerms={handleTerms}
            showOnlyWaitingRoom={showOnlyWaitingRoom}
            roomName={roomName}
            visitor={visitor}
          />
        </div>
      </div>
      <InviteDialog
        open={invitePopupOpen}
        roomName={roomName}
        visitor={visitor}
        roomType={roomType}
        numberOfInvitationSend={numberOfInvitationSend}
        onClose={() => {
          setNumberOfInvitationSend(number => number + 1);
          setInvitePopupOpen(false);
        }}
      />
    </>
  );
}
