import React, { PropsWithChildren, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core';

interface NameDialogProps {
  open: boolean;
  onClose(): void;
  handleTerms?: any;
  roomName?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  container1: {
    background: '#18181a',
    border: '2px solid #323132',
    color: '#4284f3',
    fontFamily: 'Arial',
  },
  container2: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
    fontFamily: 'Arial',
  },
  container3: {
    background: '#18181a',
    border: '2px solid #323132',
    fontFamily: 'Arial',
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    color: '#aaa',
    fontWeight: 'bolder',
  },
  textMiddle: {
    color: '#aaa',
  },
  space: {
    marginBottom: '-5px',
  },
  textLower: {
    color: '#aaa',
    fontWeight: 'bolder',
    marginBottom: '0px',
  },
  symbol: {
    color: '#aaa',
    marginBottom: '-20px',
    '& svg': {
      fill: '#aaa',
      height: '52px',
    },
  },
  btn: {
    color: 'white',
    background: '#4284f3',
    cursor: 'pointer',
  },
  smallCaps: {
    fontVariant: 'small-caps',
    fontSize: '13px',
  },
  input: {
    padding: '10px 5px 10px 10px',
    outline: 'none',
    border: '1px solid #aaa',
    borderRadius: '5px',
    background: '#18181a',
    color: 'white',
    // width:'47% !important',
    width: '100% !important',
    textOverflow: 'ellipsis',
    fontSize: '15px',
  },
  cancelBtn: {
    color: 'white',
    background: '#323132',
    cursor: 'pointer',
  },
}));

function NameDialog({ open, onClose, handleTerms, roomName }: PropsWithChildren<NameDialogProps>) {
  const classes = useStyles();
  const [name, setName] = useState<string>('');
  const handleClick = () => {
    if (name?.trim().length) {
      handleTerms(name, roomName);
    }
  };
  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle className={classes.container1}>What is your name?</DialogTitle>
      {/* <Divider /> */}
      <DialogContent className={classes.container2}>
        <DialogContentText className={classes.text}>
          <input
            name="name"
            placeholder="Name"
            value={name}
            className={classes.input}
            maxLength={16}
            onChange={e => setName(e.target.value)}
            autoComplete="off"
          />
        </DialogContentText>
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions className={classes.container3}>
        <Button className={classes.cancelBtn} onClick={onClose} variant="contained" autoFocus>
          CANCEL
        </Button>
        <Button className={classes.btn} onClick={handleClick} variant="contained" autoFocus>
          CONFIRM
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NameDialog;
