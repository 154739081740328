import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AccountDetails } from './types';
import { RootState } from './store';

export const initialState: AccountDetails = {
  userId: null,
  authToken: null,
  isHost: false,
  userName: '',
  ipAddress: null,
  userDetails: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    SET_USER_DETAILS: (state, { payload }: PayloadAction<Partial<AccountDetails>>) => {
      state.authToken = payload.authToken || state.authToken;
      state.isHost = payload.userName === 'doctor' || state.isHost;
      state.userName = payload.userName || state.userName;
      state.authToken = payload.authToken || state.authToken;
      state.ipAddress = payload.ipAddress || state.ipAddress;
      state.userDetails = payload.userDetails || state.userDetails;
      state.userId = payload.userId || state.userId;
    },
    CLEAR_STATE: (state, action) => {
      Object.assign(state, initialState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { SET_USER_DETAILS, CLEAR_STATE } = userSlice.actions;

export default userSlice.reducer;

export const selectAccount = (store: RootState) => store.account;
