import React, { useMemo } from 'react';
import Card from './VideoCard';
import styled from 'styled-components';

interface StyleType {
  backgroundColor?: string;
  minWidth?: string;
  height?: string;
  width?: string;
  childrenCount: number;
}

const VideoListContainer = styled.div<StyleType>`
  color: var(--white);
  display: grid;
  overflow-y: scroll;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;
  width: 100%;

  & > ${Card} {
    ${({ childrenCount }) => childrenCount <= 3 && `grid-column: span 2; height:100%;`}
  }
  &::-webkit-scrollbar {
    height: 0.9em;
    &:hover {
      height: 1em;
    }
  }
`;

const Wrapper = styled.div<{ showingPreview: boolean }>`
  padding: 10px;
  background-color: var(--theme-dark);
  max-height: 100vh;
  position: relative;
  overflow: hidden;
  width: 100%;
  display: grid;
  overflow-y: scroll;
  height: 100%;
  ${({ showingPreview }) =>
    showingPreview &&
    `grid-template-columns:repeat(2,minmax(0,1fr));gap:1rem;
width:100%`}
`;
const MainPreviewContainer = styled.div`
  height: 100%;
  background-color: var(--theme-dark-2);
  border-radius: 10px;
`;

export const VideoContainer: React.FC = ({ children }) => {
  const showingPreview = useMemo(
    () => (children?.length ? children?.some(ele => ele?.type?.name === 'MainPreview') : false),
    [children]
  );

  return <Wrapper showingPreview={showingPreview}>{children}</Wrapper>;
};

interface VideoListProps {
  children: React.ReactNode | React.ReactNode[];
}

export const VideoList: React.FC = ({ children }) => {
  return (
    <VideoListContainer className="list-container" childrenCount={children?.length}>
      {children}
    </VideoListContainer>
  );
};
export const MainPreview: React.FC = ({ children }) => {
  return <MainPreviewContainer className="preview-container">{children}</MainPreviewContainer>;
};
