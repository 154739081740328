import { db } from './firebase/db.config';
import firebase from 'firebase';

export const firebaseHelper = async (roomName: any) => {
  const res = await db
    .collection('roomDetails')
    .where('roomName', '==', roomName)
    .get();
  var clientDate = new Date();
  var currentDate = clientDate.getDate();
  var currentMonth = clientDate.getMonth();
  var idDocAdded;
  var id, exists, docDate, docMonth;
  res.docs.forEach(item => {
    id = item.id;
    exists = item.exists;
    docDate = item
      .data()
      .timeStamp.toDate()
      .getDate();
    docMonth = item
      .data()
      .timeStamp.toDate()
      .getMonth();
    if (exists && currentDate === docDate && currentMonth === docMonth) {
      idDocAdded = id;
    }
  });
  return idDocAdded;
};

export const updateActiveParticipants = async (participantName: any, roomName: any) => {
  try {
    const res = await db
      .collection('roomDetails')
      .where('roomName', '==', roomName)
      .get();
    var clientDate = new Date();
    var currentDate = clientDate.getDate();
    var currentMonth = clientDate.getMonth();
    var idDocAdded;
    var id, exists, docDate, docMonth;
    res.docs.forEach(item => {
      id = item.id;
      exists = item.exists;
      docDate = item
        .data()
        .timeStamp.toDate()
        .getDate();
      docMonth = item
        .data()
        .timeStamp.toDate()
        .getMonth();
      if (exists && currentDate === docDate && currentMonth === docMonth) {
        idDocAdded = id;
      }
    });

    await db
      .collection('roomDetails')
      .doc(idDocAdded)
      .update({
        activeParticipants: firebase.firestore.FieldValue.arrayRemove(participantName),
      });
    return;
  } catch (error) {
    console.log('error', error);
    return;
  }
};

export const getCredentials = async () => {
  var pusherKey, pusherCluster, pusherSecret, pusherApiId, encryptionMasterKeyBase64;
  await db
    .collection('telehealthConfig')
    .doc('MOi779jRH4zz9VSmiUvO')
    .get()
    .then(resp => {
      pusherKey = resp.data()?.pusher.PUSHER_KEY;
      pusherCluster = resp.data()?.pusher.PUSHER_CLUSTER;
      pusherSecret = resp.data()?.pusher.PUSHER_SECRET;
      pusherApiId = resp.data()?.pusher.PUSHER_APP_ID;
      encryptionMasterKeyBase64 = resp.data()?.ENCRYPTION_MASTER_KEY;
    })
    .catch(err => {});
  return { pusherKey, pusherCluster, pusherSecret, pusherApiId, encryptionMasterKeyBase64 };
};
