import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import Participant from '../Participant/Participant';
import { makeStyles, Theme } from '@material-ui/core/styles';
import useMainParticipant from '../../hooks/useMainParticipant/useMainParticipant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import useScreenShareParticipant from '../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import { axiosConfig } from '../../axiosBaseURL';
import { updateActiveParticipants } from '../../firebaseHelper';
import { functions } from '../../firebase/db.config';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress, Typography } from '@material-ui/core';
import find from 'lodash/find';
import useRoom from '../../hooks/useRoom/useRoom';
import { removeUserFromAllowedParticipantList } from '../../utils/firebaseServices/roomService';
import useAccount from '../../hooks/useAccount/useAccount';
const useStyles = makeStyles((theme: Theme) => ({
  container: {},
  participantIdentity: {
    color: '#919192',
    fontSize: '15px',
    padding: '8px 0px 8px 4px',
    [theme.breakpoints.down(750)]: {
      padding: '6px 0px 0px 4px',
      width: '60%',
    },
  },
  infoContainer: {
    padding: ' 0px 10px',
    background: '#18181a',
    borderTop: '1px solid #919192',
    // borderBottom:'1px solid #919192',
    // borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      // borderRadius: '0px 0px 10px 10px',
      display: 'inline-flex',
      // flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0px 5px',
      borderBottom: '1px solid #919192',
      background: 'transparent',
    },
  },
  infoContainer1: {
    padding: ' 0px 10px',
    background: '#18181a',
    borderTop: '1px solid #919192',
    // borderRadius: '10px',
    [theme.breakpoints.down(750)]: {
      // borderRadius: '0px 0px 10px 10px',
      display: 'inline-flex',
      // flexDirection: 'column',
      width: '100%',
      justifyContent: 'space-between',
      padding: '0px 5px',
      background: 'transparent',
    },
  },
  transparentBackground: {
    background: 'transparent',
  },
  remove: {
    float: 'right',
    padding: '0px 20px',
    cursor: 'pointer',
    height: '15px',
    '& svg': {
      fill: 'white',
    },
    [theme.breakpoints.down(750)]: {
      fontSize: '18px',
      padding: '0px',
    },
  },
}));

export default function ParticipantStatus({
  roomName,
  handleTerms,
  roomType,
  visitor,
  waitingParticipantsLength,
}: {
  roomName?: any;
  handleTerms?: any;
  roomType?: any;
  visitor?: any;
  waitingParticipantsLength?: any;
}) {
  const classes = useStyles();
  const {
    room: { localParticipant },
  } = useVideoContext();
  const [loading, setloading] = useState<boolean>(false);
  const trackParticipants = useParticipants();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const screenShareParticipant = useScreenShareParticipant();
  const mainParticipant = useMainParticipant();
  const isRemoteParticipantScreenSharing = screenShareParticipant && screenShareParticipant !== localParticipant;
  const { sessionState, roomId } = useRoom();
  const { userId } = useAccount();
  const activeParticipants = useMemo(
    () =>
      trackParticipants
        .map(participant => {
          let details = find(
            sessionState.participantsDetails,
            sessionPer => participant?.identity === sessionPer.userId
          );
          return {
            details: details,
            trackParticipant: participant,
          };
        })
        .filter(ele => ele),
    [sessionState.participantsDetails, trackParticipants]
  );
  const handleParticipant = async (identity: any) => {
    if (loading) return null;

    setloading(true);
    const payload = {
      identity,
      roomName,
      roomType: sessionState.roomType,
    };
    const removeparticipant = functions.httpsCallable('removeparticipant');
    await removeparticipant(payload)
      .then(response => {
        console.log('response', response);
        return response;
      })
      .catch(function(error) {
        console.log('error', error);
        return;
      });
    // axiosConfig
    //   .post('/removeparticipant', payload)
    //   .then(response => {
    //     return response;
    //   })
    //   .catch(function(error) {
    //     console.log('error ----', error);
    //   });
    if (userId && roomId) {
      await removeUserFromAllowedParticipantList({ roomId, userId: identity });
    }
    setloading(false);
  };
  return (
    <aside
      className={clsx(classes.container, {
        [classes.transparentBackground]: !isRemoteParticipantScreenSharing,
      })}
    >
      <div>
        {activeParticipants &&
          activeParticipants.map(participant => {
            const isSelected = participant.trackParticipant === selectedParticipant;
            const hideParticipant =
              participant.trackParticipant === mainParticipant &&
              participant.trackParticipant !== screenShareParticipant &&
              !isSelected;
            return (
              <React.Fragment key={participant.trackParticipant.sid}>
                <div
                  className={waitingParticipantsLength === 0 ? classes.infoContainer1 : classes.infoContainer}
                  key={participant.trackParticipant.sid}
                >
                  <div className={classes.participantIdentity}>
                    {participant?.details?.userName || ''}
                    {visitor === 'doctor' && visitor !== participant.trackParticipant.identity && (
                      <span className={classes.remove} onClick={() => handleParticipant(participant.details.userId)}>
                        <CloseIcon fontSize="small" />
                      </span>
                    )}
                  </div>
                  {loading ? (
                    <div className={`${classes.participantIdentity} d-flex align-items-center `}>
                      <CircularProgress size={15} className="me-2" />
                      <Typography variant="subtitle2" display="block" className="mb-0 mt-1" gutterBottom>
                        Removing...
                      </Typography>
                    </div>
                  ) : (
                    <Participant
                      key={participant.trackParticipant.sid}
                      participant={participant.trackParticipant}
                      isSelected={participant.trackParticipant === selectedParticipant}
                      onClick={() => setSelectedParticipant(participant.trackParticipant)}
                      hideParticipant={hideParticipant}
                      showParticipantStatusOnly
                    />
                  )}
                </div>
              </React.Fragment>
            );
          })}
      </div>
    </aside>
  );
}
