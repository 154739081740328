import { db } from '../../firebase/db.config';
import firebase, { firestore } from 'firebase';
import getTelehealthDocRefs from './getDocumentRefs';
import { ROOM_STATUS, ROOM_TYPE } from '../constants';
import generateRoomDocumentModel, { RoomTypes } from '../documentModals/getRoomModel';
import { CreateRoom, SessionDetails } from '../../store/types';
import { COLLECTIONS } from '../../constants';
import { initialState as sessionInititalState } from '../../store/sessionDetails';
import { union } from 'lodash';

export const getRoomByRoomName = async (roomName: any) => {
  const res = await db
    .collection('roomDetails')
    .where('roomName', '==', roomName)
    .get();
  var clientDate = new Date();
  var currentDate = clientDate.getDate();
  var currentMonth = clientDate.getMonth();
  var idDocAdded;
  var id, exists, docDate, docMonth;
  res.docs.forEach(item => {
    id = item.id;
    exists = item.exists;
    docDate = item
      .data()
      .timeStamp.toDate()
      .getDate();
    docMonth = item
      .data()
      .timeStamp.toDate()
      .getMonth();
    if (exists && currentDate === docDate && currentMonth === docMonth) {
      idDocAdded = id;
    }
  });
  return idDocAdded;
};

// join room
// if room doesnt exist create and join New  Room
// If host join diretly to allowedParticiapant
// if non host wait in waiting list
export const joinRoom = async ({
  roomName,
  roomType = ROOM_TYPE.SMALL,
  userId,
  isHost = false,
}: {
  roomName: string;
  roomType: RoomTypes;
  userId: string;
  isHost: boolean;
}): Promise<SessionDetails> => {
  try {
    const { roomDocRef } = getTelehealthDocRefs();
    const fbResponse = await roomDocRef
      .where('roomName', '==', roomName)
      .where('status', 'in', [ROOM_STATUS.IN_PROGRESS, ROOM_STATUS.INITIATED])
      .limit(1)
      .get();

    let roomDetails: any = null;
    // if roomAlready exist
    if (fbResponse.docs.length === 1) {
      roomDetails = fbResponse.docs[0].data();

      const updates = {
        waitingParticipants: roomDetails.waitingParticipants || [],
        allowedParticipants: roomDetails.allowedParticipants || [],
        participants: firestore.FieldValue.arrayUnion(userId) || [],
      };
      if (isHost) {
        updates.allowedParticipants = firestore.FieldValue.arrayUnion(userId);
      } else {
        updates.waitingParticipants = firestore.FieldValue.arrayUnion(userId);
      }
      await roomDocRef.doc(roomDetails.roomId).update({
        ...updates,
      });

      const updatedRoomDoc = await roomDocRef.doc(roomDetails.roomId).get();
      const updatedRoomDetails = updatedRoomDoc.data();
      roomDetails = {
        ...roomDetails,
        updatedRoomDetails,
      };
    } else {
      // if room doesnt exist then  create one
      const newRoom = generateRoomDocumentModel({
        activeParticipants: [],
        allowedParticipants: isHost ? [userId] : [],
        conversationId: null,
        hostId: isHost ? userId : null,
        participants: [userId],
        roomName,
        roomType,
        status: ROOM_STATUS.INITIATED,
        waitingParticipants: isHost ? [] : [userId],
      });
      const createRoomRes = await roomDocRef.doc(newRoom.roomId).set(newRoom);

      // removing all document reference and storing as string
      roomDetails = {
        ...newRoom,
      };
    }

    return {
      activeParticipants: roomDetails.activeParticipants,
      participants: roomDetails.participants,
      roomAdmin: roomDetails.hostId,
      roomId: roomDetails.roomId,
      roomName: roomDetails.roomName,
      roomType: roomDetails.roomType,
      waitingParticipants: roomDetails.waitingParticipants,
      roomDetails: roomDetails,
    } as SessionDetails;
  } catch (error) {
    return {
      ...sessionInititalState,
    };
  }
};

export const addUserToAllowedParticipantList = async ({
  userId,
  roomId,
}: {
  userId: string | null;
  roomId: string | null;
}): Promise<SessionDetails | null> => {
  try {
    const { roomDocRef } = getTelehealthDocRefs();

    if (!userId || !roomId || userId.trim() === '' || roomId.trim() === '') return null;

    const roomDetailsRes: any = await roomDocRef.doc(roomId).get();
    let newSessionDetails: SessionDetails | any = {
      ...sessionInititalState,
    };

    const roomDetailData = roomDetailsRes.data();
    if (!roomDetailsRes.exists || !roomDetailData) return null;

    if (!roomDetailData.participants.includes(userId)) return null;

    newSessionDetails = {
      ...newSessionDetails,
      ...roomDetailData,
    };
    const toBeupdated = {
      allowedParticipants: union([...newSessionDetails.allowedParticipants, userId]),
      waitingParticipants: newSessionDetails?.waitingParticipants?.filter((ele: any) => ele !== userId),
    };

    await roomDocRef.doc(roomId).update({
      ...toBeupdated,
    });

    newSessionDetails = {
      ...newSessionDetails,
      ...toBeupdated,
    };

    return {
      activeParticipants: newSessionDetails.activeParticipants || [],
      allowedParticipants: newSessionDetails.allowedParticipants || [],
      participants: newSessionDetails.participants || [],
      roomAdmin: newSessionDetails.hostId,
      roomDetails: newSessionDetails,
      roomId: newSessionDetails.roomId || null,
      roomName: newSessionDetails.roomName || null,
      roomType: newSessionDetails.roomType,
      waitingParticipants: newSessionDetails.waitingParticipants || [],
    } as SessionDetails;
  } catch (error) {
    console.log('ERROR addUserToAllowedParticipantList :', error);
    return {
      ...sessionInititalState,
    };
  }
};
export const removeUserFromAllowedParticipantList = async ({
  userId,
  roomId,
}: {
  userId: string | null;
  roomId: string | null;
}): Promise<SessionDetails | null> => {
  try {
    const { roomDocRef } = getTelehealthDocRefs();

    if (!userId || !roomId || userId.trim() === '' || roomId.trim() === '') return null;

    const roomDetailsRes: any = await roomDocRef.doc(roomId).get();
    let newSessionDetails: SessionDetails | any = {
      ...sessionInititalState,
    };

    const roomDetailData = roomDetailsRes.data();
    if (!roomDetailsRes.exists || !roomDetailData) return null;

    if (!roomDetailData.participants.includes(userId)) return null;

    newSessionDetails = {
      ...newSessionDetails,
      ...roomDetailData,
    };
    const toBeupdated = {
      allowedParticipants: union([...newSessionDetails.allowedParticipants].filter(ele => ele !== userId)),
      activeParticipants: union([...newSessionDetails.activeParticipants].filter(ele => ele !== userId)),
      waitingParticipants: union([...newSessionDetails.waitingParticipants, userId]),
    };

    await roomDocRef.doc(roomId).update({
      ...toBeupdated,
    });

    newSessionDetails = {
      ...newSessionDetails,
      ...toBeupdated,
    };

    return {
      activeParticipants: newSessionDetails.activeParticipants || [],
      allowedParticipants: newSessionDetails.allowedParticipants || [],
      participants: newSessionDetails.participants || [],
      roomAdmin: newSessionDetails.hostId,
      roomDetails: newSessionDetails,
      roomId: newSessionDetails.roomId || null,
      roomName: newSessionDetails.roomName || null,
      roomType: newSessionDetails.roomType,
      waitingParticipants: newSessionDetails.waitingParticipants || [],
    } as SessionDetails;
  } catch (error) {
    console.log('ERROR addUserToAllowedParticipantList :', error);
    return {
      ...sessionInititalState,
    };
  }
};
