export const ROOM_TYPE = {
  LARGE: 'large',
  SMALL: 'small',
} as const;

export const ROOM_STATUS = {
  INITIATED: 'initiated',
  IN_PROGRESS: 'inProgess',
  ENDED: 'ended',
  ERROR: 'error',
} as const;
