import React, { useState, useEffect, FormEvent } from 'react';
import DeviceSelectionScreen from './DeviceSelectionScreen/DeviceSelectionScreen';
// import IntroContainer from '../IntroContainer/IntroContainer';
// import MediaErrorSnackbar from './MediaErrorSnackbar/MediaErrorSnackbar';
// import PreflightTest from './PreflightTest/PreflightTest';
import RoomNameScreen from './RoomNameScreen/RoomNameScreen';
import { useAppState } from '../../state';
import { useParams } from 'react-router-dom';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import Popup from '../Popup';
import { makeStyles } from '@material-ui/core';
// import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
// import { SET_ROOM_DETAILS } from '../../store/createRoom';
import useJoinRoom from '../VideoProvider/useJoinRoom/useJoinRoom';
import { ROOM_TYPE } from '../../utils/constants';
// import { initialState } from '../../store/sessionDetails';
import useRoom from '../../hooks/useRoom/useRoom';
import { CLEAR_STATE } from '../../store/userDetails';

export enum Steps {
  roomNameStep,
  deviceSelectionStep,
}
const h1Styles = {
  roomDiv: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const useStyles = makeStyles(() => ({
  popup: {
    position: 'absolute',
    top: '45%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1,
  },
}));

export default function PreJoinScreens({
  setVisitor,
  handleTerms,
  roomType,
}: {
  setVisitor?: any;
  handleTerms?: any;
  roomType?: any;
}) {
  const classes = useStyles();
  const history = useHistory();
  const { user } = useAppState();
  const { getAudioAndVideoTracks } = useVideoContext();
  const { URLRoomName = '', URLRoomType = '' } = useParams<any>();
  const [step, setStep] = useState(Steps.roomNameStep);
  // TBR
  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');
  const [mediaError, setMediaError] = useState<Error>();
  const [nameError, setNameError] = useState<boolean>(true);
  const [showPopup, setShowPopup] = useState(false);
  const { setJoinerDetails, joinerDetails } = useJoinRoom();
  const { clearUserPersistedState } = useRoom();
  const dispatch = useDispatch();

  useEffect(() => {
    if (URLRoomName) {
      setRoomName(URLRoomName);
      if (user?.displayName) {
        setStep(Steps.deviceSelectionStep);
      }
    }
  }, [user, URLRoomName]);
  useEffect(() => {
    // if (isMobile && URLRoomName && URLRoomType) {
    //   setShowPopup(true);
    // }
    if (URLRoomName && URLRoomType) {
      setRoomName(URLRoomName);
      setStep(Steps.deviceSelectionStep);
    }
  }, []);

  useEffect(() => {
    if (step === Steps.deviceSelectionStep && !mediaError) {
      getAudioAndVideoTracks().catch(error => {
        console.dir(error);
        setMediaError(error);
      });
    }
  }, [getAudioAndVideoTracks, step, mediaError]);

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If this app is deployed as a twilio function, don't change the URL because routing isn't supported.
    if (name.trim().length !== 0) {
      setNameError(false);
      if (!window.location.origin.includes('twil.io')) {
        history.push({
          ...history.location,
          pathname: `/room/${roomName}`,
        });
      }
      dispatch(CLEAR_STATE(null));
      clearUserPersistedState();
      setJoinerDetails({
        roomName: roomName,
        userName: name,
      });

      setStep(Steps.deviceSelectionStep);
      setVisitor(name);
    } else {
      setNameError(true);
    }
  };

  const handleRoomType = (input: string) => {
    let type = input === ROOM_TYPE.LARGE ? ROOM_TYPE.LARGE : ROOM_TYPE.SMALL;
    setJoinerDetails({ roomType: type });
  };

  // TBR
  // useEffect(() => {
  //   if(name.trim().length !== 0) {
  //     setNameError(false);
  //     if (!window.location.origin.includes('twil.io')) {
  //       history.push({
  //         ...history.location,
  //         pathname: `/room/${roomName}`,
  //       });
  //     }
  //     setStep(Steps.deviceSelectionStep);
  //     setVisitor(name);
  //   } else {
  //     setNameError(true);
  //   }
  // }, [])

  // const SubContent = (
  //   <>
  //     {Video.testPreflight && <PreflightTest />}
  //     <MediaErrorSnackbar error={mediaError} />
  //   </>
  // );

  return (
    // < subContent={step === Steps.deviceSelectionStep && SubContent}>
    <div style={{ backgroundColor: 'rgb(35, 35, 37)' }}>
      {/* <div style={h1Styles.roomDiv}>
      {step === Steps.roomNameStep && (
        <RoomNameScreen
          name={name}
          roomName={roomName}
          setName={setName}
          setRoomName={setRoomName}
          handleSubmit={handleSubmit}
        />
      )}
</div>

      {step === Steps.deviceSelectionStep && (
        <DeviceSelectionScreen name={name} roomName={roomName} setStep={setStep} />
      )} */}
      {showPopup && (
        <div className={classes.popup}>
          <Popup
            text="Would you like to open it in mobile app."
            label1="No"
            label="Yes"
            handleClick1={() => setShowPopup(false)}
            handleClick={() => {
              // window.open(`endlinkScheme://com.endlinkllc.securemessenger/roomName=${URLRoomName}&roomType=small`, '_blank')?.focus();
              window.location.assign(
                `/endlinkScheme://com.endlinkllc.securemessenger/roomName=${URLRoomName}&roomType=small`
              );
            }}
            URLRoomName={URLRoomName}
          />
        </div>
      )}
      {step === Steps.roomNameStep ? (
        <div style={h1Styles.roomDiv}>
          <RoomNameScreen
            name={name}
            roomName={roomName}
            setName={setName}
            setRoomName={setRoomName}
            handleSubmit={handleSubmit}
            nameError={nameError}
            setNameError={setNameError}
            handleRoomType={handleRoomType}
            roomType={joinerDetails.roomType}
          />
        </div>
      ) : (
        <DeviceSelectionScreen
          name={name}
          setName={setName}
          handleSubmit={handleSubmit}
          roomName={roomName}
          setStep={setStep}
          handleTerms={handleTerms}
        />
      )}
    </div>
  );
}
