import React from 'react';
import './SCREEM.css';
function SCREEM() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2431.93 587.03">
      <title>Asset 5</title>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path className="cls-1" d="M146.58,236.53V331H52.08V236.53h94.5m44-44H8.08V375h182.5V192.53Z" />
          <rect className="cls-1" x="8.08" y="55.94" width="319.08" height="91.8" />
          <rect className="cls-1" x="235.81" y="55.94" width="91.8" height="319.08" />
          <text className="cls-2" transform="translate(373.06 369.76)">
            SCREEM
          </text>
          <text className="cls-3" transform="translate(2215.34 185.74)">
            ™
          </text>
          {/* <text className="cls-4" transform="translate(0 532.07)">I</text>
                <text className="cls-4" transform="translate(40.56 532.07) scale(0.7)">NDUST
                    <tspan className="cls-5" x="502.87" y="0">R</tspan>
                    <tspan x="602.89" y="0">Y</tspan>
                </text>
                <text className="cls-4" transform="translate(530.75 532.07)">-A</text>
                <text className="cls-4" transform="translate(684.81 532.07) scale(0.7)">GNOSTIC</text>
                <text className="cls-4" transform="translate(1150.4 532.07)">
                    <tspan className="cls-6"> </tspan>
                    <tspan x="35.15" y="0">A</tspan>
                </text>
                <text className="cls-4" transform="translate(1290.98 532.07) scale(0.7)">CTION</text>
                <text className="cls-4" transform="translate(1608.91 532.07)">
                    <tspan xml:space="preserve"> F</tspan>
                </text><text className="cls-4" transform="translate(1738.66 532.07) scale(0.7)">FRAMEWORK</text> */}
        </g>
      </g>
    </svg>
  );
}

export default SCREEM;
