/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import OpenApp from 'react-open-app';

import Loader from '../loader';

import './popup.m.css';

const Popup = ({
  text,
  label,
  label1,
  handleClick,
  handleClick1,
  loader1,
  label1Bold,
  labelBold,
  buttonType,
  buttonType1,
  form,
  form1,
  URLRoomName,
}) => {
  const styles1 = cx('label1', {
    bold: label1Bold,
  });
  const styles = cx('label', {
    bold: labelBold,
  });
  return (
    <div className="wrapperPopup">
      <div className="text">{text}</div>
      <div
        className={cx('button', {
          onlybutton: (label || label1) && !(label && label1),
          bothbutton: label && label1,
        })}
      >
        {label && (
          <div className={styles}>
            <OpenApp
            // href="slack://open"
            // href={`endlinkScheme://com.endlinkllc.securemessenger/roomName=${URLRoomName}&roomType=small`}
            // android={`endlinkScheme://com.endlinkllc.securemessenger/roomName=${URLRoomName}&roomType=small`}
            // ios={`endlinkScheme://com.endlinkllc.securemessenger/roomName=${URLRoomName}`}
            >
              <button onClick={handleClick}>{label}</button>
            </OpenApp>
          </div>
        )}
        {label1 &&
          (loader1 ? (
            <div className="loader">
              <Loader size="small" />
            </div>
          ) : (
            <div className={styles1}>
              <button form={form1} onClick={handleClick1} type={buttonType1}>
                {label1}
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

Popup.propTypes = {
  text: PropTypes.string,
  label: PropTypes.string,
  label1: PropTypes.string,
  handleClick: PropTypes.func,
  handleClick1: PropTypes.func,
  loader1: PropTypes.bool,
  label1Bold: PropTypes.bool,
  labelBold: PropTypes.bool,
  buttonType: PropTypes.string,
  buttonType1: PropTypes.string,
  form: PropTypes.string,
  form1: PropTypes.string,
  URLRoomName: PropTypes.string,
};

Popup.defaultProps = {
  text: '',
  label: '',
  label1: '',
  handleClick: () => {},
  handleClick1: () => {},
  loader1: false,
  label1Bold: false,
  labelBold: false,
  buttonType: 'button',
  buttonType1: 'button',
  form: '',
  form1: '',
  URLRoomName: '',
};

export default Popup;
