import React from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import {
  DialogContent,
  Typography,
  Divider,
  Dialog,
  DialogActions,
  Button,
  Theme,
  DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      // width: 'calc(100% - 35px)',
    },
  },

  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
    color: '#4284f3',
  },
  listSection: {
    margin: '3em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  container1: {
    background: '#18181a',
    border: '2px solid #323132',
    color: '#4284f3',
  },
  container2: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
  },
  container3: {
    background: '#18181a',
    border: '2px solid #323132',
  },
  text: {
    color: '#aaa',
  },
  btn: {
    color: 'white',
    background: '#4284f3',
    float: 'right',
  },
}));

export default function DeviceSelectionDialog({
  open,
  onClose,
  handleActiveSpeaker,
}: {
  open: boolean;
  onClose: () => void;
  handleActiveSpeaker?: any;
}) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.container1}>Audio and Video Settings</DialogTitle>
      <DialogContent className={classes.container}>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Video
          </Typography>
          <VideoInputList />
        </div>
        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Audio
          </Typography>
          <AudioInputList />
        </div>
        <div className={classes.listSection}>
          {handleActiveSpeaker ? <AudioOutputList handleActiveSpeaker={handleActiveSpeaker} /> : <AudioOutputList />}
        </div>
      </DialogContent>
      <DialogActions className={classes.container3}>
        <Button color="primary" variant="contained" className={classes.btn} onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
