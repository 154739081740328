import axios from 'axios';
require('dotenv').config();

export const frontURL = 'https://endlink-telehealth-5c48f.web.app';

// export const baseUrl = 'https://us-central1-employeecommunications-f8023.cloudfunctions.net/telehealth';
export const baseUrl = 'https://us-central1-employeecommunications-f8023.cloudfunctions.net';

const headers = {
  'Content-Type': 'application/json',
};
export const axiosConfig = axios.create({
  baseURL: 'https://us-central1-employeecommunications-f8023.cloudfunctions.net',
  // baseURL: 'https://us-central1-employeecommunications-f8023.cloudfunctions.net/telehealth',
  headers: headers,
});
