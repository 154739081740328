import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import Tetris from '../TetrisGame/Tetris1';
import firebase from 'firebase';
import { firebaseHelper } from '../../firebaseHelper';
import { db } from '../../firebase/db.config';

const useStyles = makeStyles((theme: Theme) => ({
  gameContainer: {
    width: '100%',
    color: 'white',
    textAlign: 'center',
    display: 'flex',
    [theme.breakpoints.down(750)]: {
      display: 'none',
    },
  },
  gameContainer1: {
    width: '100%',
    color: 'white',
    textAlign: 'center',
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
    },
  },
}));

export default function TetrisStart({ roomName, visitor }: { roomName?: any; visitor?: any }) {
  const classes = useStyles();
  useEffect(() => {
    var launchTime = firebase.firestore.Timestamp.now();
    var id: any;
    const updateData = async () => {
      id = await firebaseHelper(roomName);
    };
    updateData();
    return () => {
      var closeTime = firebase.firestore.Timestamp.now();
      var actionObj = {
        visitor,
        launchTime,
        closeTime,
        feature: 'Tetris',
      };
      db.collection('roomDetails')
        .doc(id)
        .update({
          entertainmentActions: firebase.firestore.FieldValue.arrayUnion(actionObj),
        });
    };
  }, []);
  return (
    <>
      <div className={classes.gameContainer}>
        <Tetris boardWidth="10" boardHeight="20" />
      </div>
      <div className={classes.gameContainer1}>
        <Tetris boardWidth="10" boardHeight="20" />
      </div>
    </>
  );
}
