import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--theme-dark-2);
  color: var(--white);
  border-radius: 10px;
  height: 15rem;
  /* margin:10px; */
  /* padding:10px; */

  /* moblie device */
  @media (max-width: 480px) {
    min-width: 480px;
  }
  /* iPads, Tablets */
  @media (min-width: 481px) and (max-width: 768px) {
    min-width: 480px;
  }
  /* Small screens, laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    min-width: 350px;
  }
  /* Desktops, large screens */
  @media (min-width: 1025px) and (max-width: 1200px) {
    min-width: 400px;
  }
  /* Extra large screens, TV */
  @media (min-width: 1201px) {
  }
`;

// const  VideoCard : React.FC  = function({children}) {
//   return (
//     <Container>{children}</Container>
//   )
// }

export default Container;
