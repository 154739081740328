import React, { ChangeEvent, FormEvent, useState } from 'react';
import { Typography, makeStyles, Theme, Radio } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
  gutterBottom: {
    color: 'white',
    [theme.breakpoints.down('xs')]: {
      margin: '1em',
    },
  },
  inputContainer: {
    margin: '1.5em 0.79em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '1.5em 0.5em 1.5em 0em',
      display: 'block',
    },
    display: 'flex',
    width: '100%',
  },
  radioGrpContainer: {
    margin: '1.5em 0.79em',
    '& div:not(:last-child)': {
      marginRight: '1em',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '1.5em 0.5em 1.5em 0em',
      display: 'block',
      padding: '0px 10px',
    },
    display: 'flex',
    width: '100%',
  },
  radioContainer: {
    width: '90%',
    color: 'white',
    fontSize: '18px',
  },
  radioContainer1: {
    width: '100%',
    position: 'relative',
    color: 'white',
    fontSize: '18px',
  },
  deactiveRadio: {
    '& .MuiIconButton-label': {
      color: 'white',
      marginTop: '-5px',
    },
  },
  align: {
    marginLeft: '-5px',
  },
  activeRadio: {
    '& .MuiIconButton-label': {
      color: '#4284f3',
      marginTop: '-5px',
    },
  },
  textFieldContainer: {
    width: '100%',
  },
  textFieldContainer1: {
    display: 'flex',
    width: '100%',
    position: 'relative',
  },
  button: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    margin: 'auto',
  },
  continueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0em 1em',
    },
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      margin: '0em 1em',
    },
    backgroundColor: '#323132',
    height: '35px',
    borderRadius: '10px',
    border: 'none',
    color: 'white',
  },
  activeContinueButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      margin: '0em 1em',
    },
    [theme.breakpoints.down('xs')]: {
      width: '94%',
      margin: '0em 1em',
    },
    backgroundColor: '#4284f3',
    height: '35px',
    borderRadius: '10px',
    border: 'none',
    color: 'white',
    cursor: 'pointer',
    outline: 'none',
  },
  input: {
    outline: 'none',
    backgroundColor: '#323132',
    borderRadius: '10px',
    border: 'none',
    width: '90%',
    fontSize: '18px',
    height: '35px',
    color: '#4284f3',
    paddingLeft: '10px',
    [theme.breakpoints.down(300)]: {
      width: '85%',
      margin: 'auto',
      display: 'block',
      marginBottom: '1em',
    },
    [theme.breakpoints.between(300, 400)]: {
      width: '90%',
      margin: 'auto',
      display: 'block',
      marginBottom: '1em',
    },
    [theme.breakpoints.between(400, 500)]: {
      width: '92%',
      margin: 'auto',
      display: 'block',
      marginBottom: '1em',
    },
    [theme.breakpoints.between(500, 'xs')]: {
      width: '93%',
      margin: 'auto',
      display: 'block',
      marginBottom: '1em',
    },
  },
  input1: {
    outline: 'none',
    backgroundColor: '#323132',
    borderRadius: '10px',
    border: 'none',
    width: '90%',
    fontSize: '18px',
    height: '35px',
    color: '#4284f3',
    paddingLeft: '102px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '0em 1em',
    },
  },
  fixedText: {
    position: 'absolute',
    left: '10px',
    color: 'white',
    top: '6px',
    fontSize: '16px',
    // [theme.breakpoints.down('sm')]: {
    //   left: '4px',
    // },
    [theme.breakpoints.down('xs')]: {
      left: '26px',
    },
  },
  errorText: {
    color: '#c62828',
    marginTop: '0.5em',
    marginLeft: '0.5em',
    fontSize: '15px',
    [theme.breakpoints.down('xs')]: {
      margin: '-1em 0em 2em 2em',
    },
  },
}));

interface RoomNameScreenProps {
  name: string;
  roomName: string;
  setName: (name: string) => void;
  setRoomName: (roomName: string) => void;
  handleSubmit: (event: FormEvent<HTMLFormElement>) => void;
  nameError: boolean;
  setNameError: (nameerror: boolean) => void;
  handleRoomType?: any;
  roomType?: any;
}

export default function RoomNameScreen({
  name,
  roomName,
  setName,
  setRoomName,
  handleSubmit,
  nameError,
  setNameError,
  handleRoomType,
  roomType,
}: RoomNameScreenProps) {
  const classes = useStyles();
  const { user } = useAppState();
  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setNameError(false);
    setName(event.target.value);
  };
  const handleRoomNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setRoomName(event.target.value);
  };
  const hasUsername = !window.location.search.includes('customIdentity=true') && user?.displayName;
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleRoomType(event.target.value);
  };
  return (
    <div>
      <Typography variant="h5" className={classes.gutterBottom}>
        Tell us your name and your eVisit room type and name
      </Typography>
      <form onSubmit={handleSubmit}>
        <div className={classes.radioGrpContainer}>
          <div className={classes.radioContainer}>
            <Radio
              checked={roomType === 'small'}
              onChange={handleChange}
              value="small"
              color="default"
              name="radio-button-demo"
              className={roomType === 'small' ? classes.activeRadio : classes.deactiveRadio}
            />
            <span className={classes.align}>TeleHealth (1-1 Session)</span>
          </div>
          <div className={classes.radioContainer1}>
            <Radio
              checked={roomType === 'large'}
              onChange={handleChange}
              value="large"
              color="default"
              name="radio-button-demo"
              className={roomType === 'large' ? classes.activeRadio : classes.deactiveRadio}
            />
            <span className={classes.align}>
              TeleShare<sup style={{ fontSize: '7px' }}>TM</sup> (Group Session)
            </span>
          </div>
        </div>
        <div className={classes.inputContainer}>
          {!hasUsername && (
            <div className={classes.textFieldContainer}>
              <input
                autoFocus={true}
                className={classes.input}
                value={name}
                onChange={handleNameChange}
                placeholder="Enter your name here!"
                maxLength={16}
              />
              {/* {nameError? <div className={classes.errorText}>Please enter your name</div>:<div></div>} */}
            </div>
          )}
          <div className={classes.textFieldContainer1}>
            <input
              id="input-room-name"
              placeholder="room name"
              className={classes.input1}
              value={roomName}
              onChange={handleRoomNameChange}
            />
            <label htmlFor="input-room-name" className={classes.fixedText}>
              endlink.com/
            </label>
          </div>
        </div>
        {name.trim().length ? (
          <div className={classes.button}>
            <button
              type="submit"
              disabled={!roomName}
              className={!roomName ? classes.continueButton : classes.activeContinueButton}
            >
              Join eVisit Room
            </button>
          </div>
        ) : (
          <div className={classes.button}>
            <button type="submit" disabled={true} className={classes.continueButton}>
              Join eVisit Room
            </button>
          </div>
        )}
      </form>
    </div>
  );
}
