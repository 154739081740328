// import { db } from '../../firebase/db.config';
// import firebase, { firestore } from 'firebase';
import getTelehealthDocRefs from './getDocumentRefs';
// import { ROOM_STATUS, ROOM_TYPE } from '../constants';
// import generateRoomDocumentModel, { RoomTypes } from '../documentModals/getRoomModel';
import { AccountDetails } from '../../store/types';
// import { COLLECTIONS } from '../../constants';
import { initialState as userDetailsInititalState } from '../../store/userDetails';
// import { initialState as sessionInititalState } from '../../store/sessionDetails';
import generateUserDocumentModel, { UserModal } from '../documentModals/getUserModel';
import firebase from 'firebase';

// join room
// if room doesnt exist create and join New  Room
// If host join diretly to allowedParticiapant
// if non host wait in waiting list
export const createUser = async ({
  userName,
  isHost = false,
}: {
  userName: string;
  isHost: boolean;
}): Promise<AccountDetails> => {
  try {
    const { userDocRef } = getTelehealthDocRefs();

    const newUser: UserModal = generateUserDocumentModel({
      activities: [],
      isHost,
      rooms: [],
      userName,
    });

    await userDocRef.doc(newUser.userId).set(newUser);

    return {
      authToken: null,
      ipAddress: null,
      isHost: newUser.isHost,
      userDetails: newUser,
      userId: newUser.userId,
      userName: newUser.userName,
    } as AccountDetails;
  } catch (error) {
    console.log('ERORR---', error);

    return {
      ...userDetailsInititalState,
    };
  }
};

export const updateIpOfUser = async ({
  userId,
  ipAddress,
  roomId,
}: {
  userId: string;
  ipAddress: string;
  roomId: string;
}) => {
  try {
    if (userId?.trim()?.length <= 0 && ipAddress?.trim()?.length <= 0 && roomId?.trim()?.length <= 0) {
      return;
    }
    const { userDocRef } = getTelehealthDocRefs();

    const toBeupdated = {
      ipAddress,
      roomId,
      timeStamp: firebase.firestore.Timestamp.now(),
    };

    await userDocRef.doc(userId).update({
      activities: firebase.firestore.FieldValue.arrayUnion(toBeupdated),
    });
    return;
  } catch (error) {
    if (error instanceof Error) {
      console.log('Updating Ip failed', error.message);
    }
    return;
  }
};
