import React, { useEffect, useMemo, useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import { FormControl, MenuItem, Typography, Select, FormHelperText } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import { makeStyles, Theme } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { useMediaPermission, useVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import { isEmpty } from 'lodash';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) => ({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
    [theme.breakpoints.down(600)]: {
      width: '250px',
    },
  },
  titleHeader: {
    color: '#919192',
  },
  options: {
    background: '#18181a',
    color: '#919192',
    marginBottom: '-8px',
    marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
      paddingBottom: '12px',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  buttonoptions: {
    background: '#18181a',
    color: '#919192',
    marginBottom: '-8px',
    marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    padding: '12px 8px 8px 8px ',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  text: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  wrapper: {
    margin: '0.4em 0 1.4em 0',
  },
  deviceButton: {
    width: '100%',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '2px 0',
    '&.disabled': {
      color: 'var(--bs-gray-600)',
    },
  },
  videoicon: {
    position: 'absolute',
    right: '6px',
    top: '-32px',
    '& button': {
      padding: '0',
      minWidth: '0',
    },
    '& .Mui-disabled path': {
      fill: 'var(--bs-gray-600)',
    },
  },
  p_relative: {
    position: 'relative',
  },
  // activeDeviceButton: {
  //   background: '#4284f3',
  //   '&:hover': {
  //     backgroundColor: '#4284f3',
  //     color: 'white',
  //   },
  // },
}));

interface DeviceSelectionScreenProps {
  disableButtons?: any;
  handleVideo?: any;
  video?: boolean;
}

export default function VideoInputList({ handleVideo, disableButtons, video }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const videoInputDevices = useVideoInputDevices();
  const { localTracks } = useVideoContext();
  const localVideoTrack = useMemo(() => localTracks.find(track => track.kind === 'video') as LocalVideoTrack, [
    localTracks,
  ]);
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const { cameraGrantend } = useMediaPermission();
  const { turnOnVideo, activeDeviceId, setActiveDeviceId, hasVideoTrack } = useLocalVideoToggle();
  // const localVideoInputDeviceId = useMemo(()=> mediaStreamTrack?.getSettings().deviceId,[mediaStreamTrack])

  // intitially set device on  page Load
  useEffect(() => {
    if ((isEmpty(activeDeviceId) || activeDeviceId.length === 0) && videoInputDevices.length > 0) {
      setActiveDeviceId(videoInputDevices[0].deviceId);
      replaceTrack(videoInputDevices[0].deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoInputDevices]);

  // turn video on if has Camera permission and has a camera device
  useEffect(() => {
    if (activeDeviceId && activeDeviceId !== 'muted' && cameraGrantend) {
      turnOnVideo();
    } else if (!hasVideoTrack && activeDeviceId) {
      // turnOnVideo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeDeviceId, cameraGrantend]);

  const deviceHandler = (deviceId: string) => {
    replaceTrack(deviceId);
    setActiveDeviceId(deviceId);
  };

  function replaceTrack(newDeviceId: string) {
    try {
      window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);
      localVideoTrack.restart({
        ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
        deviceId: { exact: newDeviceId },
      });
      setActiveDeviceId(newDeviceId);
    } catch (error) {}
  }
  // console.log("localVideoInputDeviceId", localVideoInputDeviceId, "temp", temp);
  return (
    <div className={classes.wrapper}>
      {/* {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )} */}
      {/* {videoInputDevices.length > 1 ? ( */}
      <div className={classes.p_relative}>
        <FormControl disabled={!cameraGrantend} fullWidth error={!cameraGrantend}>
          {/* <Typography className={classes.titleHeader} variant="subtitle2" gutterBottom>
            Video Input
          </Typography> */}
          <Select
            onChange={e => {
              if (e.target.value) {
                deviceHandler(e.target.value as string);
              }
            }}
            // value={localVideoInputDeviceId}
            value={activeDeviceId}
            variant="outlined"
            className={classes.text}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {cameraGrantend &&
              videoInputDevices.map(device => (
                <MenuItem
                  className={`${classes.options} ${videoInputDevices.length > 1 &&
                    device.deviceId === '1234' &&
                    'd-none'} `}
                  value={device.deviceId}
                  key={device.deviceId}
                >
                  {device.label}
                </MenuItem>
              ))}
            <MenuItem className={classes.buttonoptions} value={'muted'}>
              <ToggleVideoButton className={classes.deviceButton} disabled={disableButtons} handleVideo={handleVideo} />
            </MenuItem>
          </Select>
          <FormHelperText>
            {!cameraGrantend &&
              'You have denied camera access. Please adjust your browser settings to allow microphone access.'}
          </FormHelperText>
        </FormControl>
        <div className={classes.videoicon}>
          {/* <AudioLevelIndicator audioTrack={localAudioTrack} color="white" /> */}
          <ToggleVideoButton
            hideText
            className={`${classes.deviceButton} ${!cameraGrantend && 'disabled'}`}
            disabled={disableButtons || !cameraGrantend}
            handleVideo={handleVideo}
          />
        </div>
      </div>
      {/* // ) : (
      //   <>
      //     <Typography className={classes.titleHeader} variant="subtitle2" gutterBottom>
      //       Video Input
      //     </Typography>
      //     <Typography className={classes.text}>
      //       {localVideoTrack?.mediaStreamTrack.label || 'No Local Video'}
      //     </Typography>
      //   </>
      // )} */}
    </div>
  );
}
