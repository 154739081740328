import React, { useCallback, useRef } from 'react';
import Button from '@material-ui/core/Button';
import VideoOffIcon from '../../../icons/VideoOffIcon';
import VideoOnIcon from '../../../icons/VideoOnIcon';
import { makeStyles, Theme } from '@material-ui/core';

import { useHasVideoInputDevices } from '../../../hooks/deviceHooks/deviceHooks';
import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: '100%',
    justifyContent: 'left',
    height: '30px',
    [theme.breakpoints.down(750)]: {
      height: '34px',
    },
  },
}));
export default function ToggleVideoButton(props: {
  disabled?: boolean;
  className?: string;
  handleVideo?: any;
  hideText?: boolean;
}) {
  const classes = useStyles();
  const { hasVideoTrack: isVideoEnabled, toggleVideo: toggleVideoEnabled } = useLocalVideoToggle();
  const lastClickTimeRef = useRef(0);
  const hasVideoDevices = useHasVideoInputDevices();
  const toggleVideo = useCallback(() => {
    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [toggleVideoEnabled]);

  if (props.handleVideo) {
    // props.handleVideo(isVideoEnabled);
  }
  // return (
  //   <Button
  //     className={props.className}
  //     onClick={toggleVideo}
  //     disabled={!hasVideoDevices || props.disabled}
  //     startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
  //   >
  //     {props.hideText ? '':
  //     !hasVideoDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
  //   </Button>
  // );
  return props.hideText ? (
    <Button
      className={classes.button}
      onClick={toggleVideo}
      disabled={!hasVideoDevices || props.disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon eVisit /> : <VideoOffIcon eVisit />}
    ></Button>
  ) : (
    <Button
      className={props.className}
      onClick={toggleVideo}
      disabled={!hasVideoDevices || props.disabled}
      startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
    >
      {!hasVideoDevices ? 'No Video' : isVideoEnabled ? 'Stop Video' : 'Start Video'}
    </Button>
  );
}
