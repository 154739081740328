import React from 'react';

function ChevronDown() {
  return (
    <div>
      <svg xmlns="http://www.w3.org/2000/svg" height="15px" width="15px" viewBox="0 0 448 512">
        <path
          fill="white"
          d="M420 168C412 158 396 157 387 166L224 316L61 166C52 157 36 158 28 168L6 192C-3 202 -2 217 8 226L210 411C218 418 230 418 238 411L440 226C450 217 451 202 442 192L420 168Z"
        />
      </svg>
    </div>
  );
}

export default ChevronDown;
