import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContentText from '@material-ui/core/DialogContentText';
import enhanceMessage from './enhanceMessage';
import { TwilioError } from 'twilio-video';
import { makeStyles, Theme } from '@material-ui/core';

interface ErrorDialogProps {
  dismissError: Function;
  error: TwilioError | null;
}

const useStyles = makeStyles((theme: Theme) => ({
  container1: {
    borderTop: '2px solid #919192',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
    color: '#4284f3',
  },
  container2: {
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
  },
  container3: {
    borderBottom: '2px solid #919192',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
  },
  text: {
    color: '#919192',
  },
  btn: {
    color: '#4284f3',
  },
}));

interface DynamicStyleObject {
  [key: string]: string;
}
const knownErrors: DynamicStyleObject = {
  '53001': ' Unable to automatically rejoin meeting. Try accepting the terms and conditions again.',
  '53118': 'The Session has been Ended by the host',
};

function ErrorDialog({ dismissError, error }: PropsWithChildren<ErrorDialogProps>) {
  const { message, code } = error || {};
  const classes = useStyles();
  const enhancedMessage = enhanceMessage(message, code);

  return (
    <Dialog open={error !== null} onClose={() => dismissError()} fullWidth={true} maxWidth="xs">
      <DialogTitle className={classes.container1}>ERROR</DialogTitle>
      <DialogContent className={classes.container2}>
        <DialogContentText className={classes.text}>{enhancedMessage}</DialogContentText>
        {Object.keys(knownErrors).includes(`${code}`) && (
          <DialogContentText className={classes.text}>{knownErrors[`${code}`]}</DialogContentText>
        )}
        {Boolean(code) && !Object.keys(knownErrors).includes(`${code}`) && (
          <pre>
            <code className={classes.text}>Error Code: {code}</code>
          </pre>
        )}
      </DialogContent>
      <DialogActions className={classes.container3}>
        <Button onClick={() => dismissError()} className={classes.btn} autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ErrorDialog;
