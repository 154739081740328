import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import LeaveIcon from '../../../icons/LeaveIcon';
import { Button } from '@material-ui/core';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { updateActiveParticipants } from '../../../firebaseHelper';
import { PARTICIPANT_TYPES } from '../../../utils/particiapantsTypes';
import useRoom from '../../../hooks/useRoom/useRoom';
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: '#323132',
      borderRadius: '10px',
      color: 'white',
      marginBottom: '10px',
      '&:hover': {
        background: 'red',
      },
      [theme.breakpoints.up(750)]: {
        height: '35px',
      },
      [theme.breakpoints.down(750)]: {
        width: '99%',
        left: '1%',
      },
    },
    buttonIcon: {
      paddingRight: '5px',
      paddingTop: '7px',
    },
  })
);

export default function EndCallButton(props: {
  className?: string;
  handleTerms?: any;
  showOnlyWaitingRoom?: boolean;
  visitor?: any;
  roomName?: any;
}) {
  const classes = useStyles();
  const { room } = useVideoContext();
  const { endSession, leaveSession } = useRoom();

  const handleClick = () => {
    if (!props.showOnlyWaitingRoom) room.disconnect();
    props.handleTerms();
    if (props.visitor && props.roomName) {
      leaveSession();
      // updateActiveParticipants(props.visitor, props.roomName);
    }
  };

  return (
    <>
      <Button onClick={handleClick} className={clsx(classes.button, props.className)} data-cy-disconnect>
        <span className={classes.buttonIcon}>
          <LeaveIcon />
        </span>
        Leave eVisit
      </Button>
      {props.visitor === PARTICIPANT_TYPES.DOCTOR && (
        <Button onClick={() => endSession()} className={clsx(classes.button, props.className)} data-cy-disconnect>
          <span className={classes.buttonIcon}>
            <LeaveIcon />
          </span>
          End Session
        </Button>
      )}
    </>
  );
}
