import React from 'react';
import Button from '@material-ui/core/Button';
import MicIcon from '../../../icons/MicIcon';
import MicOffIcon from '../../../icons/MicOffIcon';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function ToggleAudioButton(props: {
  disabled?: boolean;
  className?: string;
  handleAudio?: any;
  hideText?: boolean;
}) {
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { localTracks } = useVideoContext();
  const hasAudioTrack = localTracks.some(track => track.kind === 'audio');
  if (props.handleAudio) {
    props.handleAudio(isAudioEnabled);
  }
  // return (
  //   <Button
  //     className={props.className}
  //     onClick={toggleAudioEnabled}
  //     disabled={!hasAudioTrack || props.disabled}
  //     startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
  //     data-cy-audio-toggle
  //   >
  //     {props.hideText ? '' :
  //     !hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}
  //   </Button>
  // );
  return props.hideText ? (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      startIcon={isAudioEnabled ? <MicIcon eVisit /> : <MicOffIcon eVisit />}
      data-cy-audio-toggle
    ></Button>
  ) : (
    <Button
      className={props.className}
      onClick={toggleAudioEnabled}
      disabled={!hasAudioTrack || props.disabled}
      startIcon={isAudioEnabled ? <MicIcon /> : <MicOffIcon />}
      data-cy-audio-toggle
    >
      <span>
        {'  '}
        {!hasAudioTrack ? 'No Audio' : isAudioEnabled ? 'Mute' : 'Unmute'}
      </span>
    </Button>
  );
}
