import React, { useCallback } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Theme,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { inputLabels, Settings } from '../../state/settings/settingsReducer';
import { RenderDimensions } from '../../state/settings/renderDimensions';
import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    background: '#18181a',
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    width: '600px',
    minHeight: '400px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  paper: {
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  formControl: {
    display: 'block',
    margin: '1.5em 0',
    '&:first-child': {
      margin: '0 0 1.5em 0',
    },
  },
  label: {
    color: '#919192',
    width: '133%', // Labels have scale(0.75) applied to them, so this effectively makes the width 100%
  },
  container1: {
    background: '#18181a',
    border: '2px solid #323132',
    color: '#4284f3',
  },
  container2: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
  },
  container3: {
    background: '#18181a',
    border: '2px solid #323132',
  },
  text: {
    color: '#aaa',
  },
  btn: {
    color: 'white',
    background: '#4284f3',
    float: 'right',
  },
  optionHeader: {
    color: '#919192',
    borderColor: 'white',
  },
  optionHeader1: {
    color: 'white',
    borderColor: 'white',
  },
  options: {
    background: '#18181a',
    color: '#919192',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    borderTop: '2px solid #919192',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
      marginTop: '-8px',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
      marginBottom: '-8px',
    },
  },
  select: {
    color: 'white',
    '&:before': {
      borderColor: 'white',
    },
  },
  select1: {
    color: 'white',
    borderColor: 'white',
    borderBottom: '1px solid white',
    '& .Mui-disabled': {
      color: '#919192',
    },
  },
  icon: {
    fill: 'white',
  },
  textDisabled: {
    color: 'red',
  },
}));

const withDefault = (val?: string) => (typeof val === 'undefined' ? 'default' : val);

// const RenderDimensionItems = RenderDimensions.map(({ label, value }) => (

//   <MenuItem value={value} key={value} >
//     {label}
//   </MenuItem>
// ));

export default function ConnectionOptionsDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();
  const { settings, dispatchSetting } = useAppState();
  const roomState = useRoomState();
  const isDisabled = roomState !== 'disconnected';

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      dispatchSetting({ name: e.target.name as keyof Settings, value: e.target.value as string });
    },
    [dispatchSetting]
  );

  const handleNumberChange = useCallback(
    (e: React.ChangeEvent<{ value: unknown; name?: string }>) => {
      if (!/[^\d]/.test(e.target.value as string)) handleChange(e);
    },
    [handleChange]
  );

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle className={classes.container1}>Connection Settings</DialogTitle>
      {/* <Divider /> */}
      <DialogContent className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className={classes.textDisabled} hidden={!isDisabled} variant="body2">
              These settings cannot be changed when connected to a room.
            </Typography>
          </Grid>

          <Grid item sm={6} xs={12}>
            <FormControl className={classes.formControl}>
              <InputLabel id={inputLabels.dominantSpeakerPriority} className={classes.optionHeader}>
                Dominant Speaker Priority:
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.dominantSpeakerPriority}
                label={inputLabels.dominantSpeakerPriority}
                value={withDefault(settings.dominantSpeakerPriority)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem className={classes.options} value="low">
                  Low
                </MenuItem>
                <MenuItem className={classes.options} value="standard">
                  Standard
                </MenuItem>
                <MenuItem className={classes.options} value="high">
                  High
                </MenuItem>
                <MenuItem className={classes.options} value="default">
                  Server Default
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id={inputLabels.trackSwitchOffMode} className={classes.optionHeader}>
                Track Switch Off Mode:
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.trackSwitchOffMode}
                label={inputLabels.trackSwitchOffMode}
                value={withDefault(settings.trackSwitchOffMode)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem className={classes.options} value="predicted">
                  Predicted
                </MenuItem>
                <MenuItem className={classes.options} value="detected">
                  Detected
                </MenuItem>
                <MenuItem className={classes.options} value="disabled">
                  Disabled
                </MenuItem>
                <MenuItem className={classes.options} value="default">
                  Server Default
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <InputLabel id={inputLabels.bandwidthProfileMode} className={classes.optionHeader}>
                Mode:
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.bandwidthProfileMode}
                label={inputLabels.bandwidthProfileMode}
                value={withDefault(settings.bandwidthProfileMode)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                <MenuItem className={classes.options} value="grid">
                  Grid
                </MenuItem>
                <MenuItem className={classes.options} value="collaboration">
                  Collaboration
                </MenuItem>
                <MenuItem className={classes.options} value="presentation">
                  Presentation
                </MenuItem>
                <MenuItem className={classes.options} value="default">
                  Server Default
                </MenuItem>
              </Select>
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                disabled={isDisabled}
                fullWidth
                id={inputLabels.maxTracks}
                label="Max Tracks"
                placeholder="Leave blank for no limit"
                name={inputLabels.maxTracks}
                value={withDefault(settings.maxTracks)}
                onChange={handleNumberChange}
                className={classes.select1}
                inputProps={{ className: classes.optionHeader1 }}
              />
            </FormControl>

            <FormControl className={classes.formControl}>
              <TextField
                disabled={isDisabled}
                fullWidth
                id={inputLabels.maxAudioBitrate}
                label="Max Audio Bitrate"
                placeholder="Leave blank for no limit"
                name={inputLabels.maxAudioBitrate}
                value={withDefault(settings.maxAudioBitrate)}
                onChange={handleNumberChange}
                className={classes.select1}
                color="secondary"
                inputProps={{ className: classes.optionHeader1 }}
              />
            </FormControl>
          </Grid>
          <Grid item sm={6} xs={12}>
            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id={inputLabels.renderDimensionLow} className={classes.label}>
                Render Dimension (Low Priority):
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.renderDimensionLow}
                label={inputLabels.renderDimensionLow}
                value={withDefault(settings.renderDimensionLow)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                {RenderDimensions.map(({ label, value }) => (
                  <MenuItem value={value} key={value} className={classes.options}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id={inputLabels.renderDimensionStandard} className={classes.label}>
                Render Dimension (Standard Priority):
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.renderDimensionStandard}
                label={inputLabels.renderDimensionStandard}
                value={withDefault(settings.renderDimensionStandard)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                {RenderDimensions.map(({ label, value }) => (
                  <MenuItem value={value} key={value} className={classes.options}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl fullWidth className={classes.formControl}>
              <InputLabel id={inputLabels.renderDimensionHigh} className={classes.label}>
                Render Dimension (High Priority):
              </InputLabel>
              <Select
                fullWidth
                disabled={isDisabled}
                name={inputLabels.renderDimensionHigh}
                label={inputLabels.renderDimensionHigh}
                value={withDefault(settings.renderDimensionHigh)}
                onChange={handleChange}
                className={classes.select}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
              >
                {RenderDimensions.map(({ label, value }) => (
                  <MenuItem value={value} key={value} className={classes.options}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions className={classes.container3}>
        <Button className={classes.btn} variant="contained" onClick={onClose}>
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
}
