import React, { useEffect, useState } from 'react';
// import AudioLevelIndicator from '../../AudioLevelIndicator/AudioLevelIndicator';
import { LocalAudioTrack } from 'twilio-video';
import { FormControl, MenuItem, Select, FormHelperText } from '@material-ui/core';
import { SELECTED_AUDIO_INPUT_KEY } from '../../../constants';
import { useAudioInputDevices, useMediaPermission } from '../../../hooks/deviceHooks/deviceHooks';
// import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { makeStyles } from '@material-ui/core/styles';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
  titleHeader: {
    color: '#919192',
  },
  text: {
    color: 'white',
  },
  options: {
    background: '#18181a',
    color: '#919192',
    // marginBottom: '-8px',
    marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
      paddingBottom: '14px',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  buttonoptions: {
    background: '#18181a',
    color: '#919192',
    marginBottom: '-8px',
    // marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  select: {
    color: 'white',
  },
  icon: {
    fill: 'white',
  },
  deviceButton: {
    width: '100%',
    color: 'white',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  wrapper: {
    margin: '0.4em 0 1.4em 0',
  },
  microphone: {
    position: 'absolute',
    right: '12px',
    top: '-28px',
    '& button': {
      padding: '0',
      minWidth: '0',
    },
  },
  p_relative: {
    position: 'relative',
  },
});
interface DeviceSelectionScreenProps {
  disableButtons?: any;
  handleAudio?: any;
}
export default function AudioInputList({ disableButtons, handleAudio }: DeviceSelectionScreenProps) {
  const classes = useStyles();
  const { micGranted = false } = useMediaPermission();
  const audioInputDevices = useAudioInputDevices();
  const { localTracks } = useVideoContext();
  const [activeDeviceId, setActiveDeviceId] = useState<string>('');
  const localAudioTrack = localTracks.find(track => track.kind === 'audio') as LocalAudioTrack;
  // const mediaStreamTrack = useMediaStreamTrack(localAudioTrack);
  // const localAudioInputDeviceId = mediaStreamTrack?.getSettings().deviceId;

  function replaceTrack(newDeviceId: string) {
    window.localStorage.setItem(SELECTED_AUDIO_INPUT_KEY, newDeviceId);
    localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  }

  useEffect(() => {
    if (isEmpty(activeDeviceId)) setActiveDeviceId(audioInputDevices[0]?.deviceId || '');
  }, [audioInputDevices]);

  useEffect(() => {
    replaceTrack(activeDeviceId as string);
  }, [activeDeviceId]);

  return (
    <div className={classes.wrapper}>
      {/* <Typography className={classes.titleHeader} variant="subtitle2" gutterBottom>
        Audio Input
      </Typography> */}
      <div className={classes.p_relative}>
        <div className="inputSelect">
          {/* {audioInputDevices.length > 1 ? ( */}
          <FormControl fullWidth error={!micGranted} disabled={!micGranted}>
            <Select
              onChange={e => setActiveDeviceId(e?.target?.value as string)}
              value={activeDeviceId}
              variant="outlined"
              className={classes.select}
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
            >
              {micGranted &&
                audioInputDevices.map(device => (
                  <MenuItem value={device.deviceId} key={device.deviceId} className={classes.options}>
                    <div>{device.label}</div>
                  </MenuItem>
                ))}
              <li className={classes.buttonoptions}>
                <ToggleAudioButton
                  className={classes.deviceButton}
                  disabled={disableButtons}
                  handleAudio={handleAudio}
                />
              </li>
            </Select>
            <FormHelperText>
              {!micGranted &&
                'You have denied microphone access. Please adjust your browser settings to allow microphone access.'}
            </FormHelperText>
          </FormControl>
          {/* ) : (
            <Typography className={classes.text}>
              {localAudioTrack?.mediaStreamTrack.label || 'No Local Audio'}
            </Typography>
          )} */}
        </div>
        <div className={classes.microphone}>
          {/* <AudioLevelIndicator audioTrack={localAudioTrack} color="white" /> */}
          <ToggleAudioButton hideText />
        </div>
      </div>
    </div>
  );
}
