import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './ChatInput.css';
interface ChatInputProps {
  typing?: any;
  text?: any;
  handleEnter?: any;
  handleTextChange?: any;
  handleSubmit?: any;
}
const useStyles = makeStyles((theme: Theme) => ({
  inputBoxContainer: {
    color: 'white',
    position: 'relative',
    left: '1%',
    height: 'calc(18% - 40px)',
    width: '99%',
    borderRadius: '0px 0px 10px 10px ',
    [theme.breakpoints.down(750)]: {
      left: '1%',
      width: '98%',
      borderRadius: '10px',
      bottom: '15%',
      height: '25%',
    },
  },
  inputBoxBackground: {
    // background: '#18181a',
  },
  inputBox: {
    background: '#18181a',
    outline: 'none',
    border: 'none',
    height: '100%',
    // height: 'calc(100% - 15px)',
    width: '100%',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'arial',
    resize: 'none',
    paddingRight: '45px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    caretColor: '#4284f3',
    scrollbarColor: 'black',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'blue',
    },
    [theme.breakpoints.down(750)]: {
      borderRadius: '10px',
    },
  },
  inputBoxTyping: {
    background: '#18181a',
    outline: 'none',
    border: 'none',
    height: '75%',
    width: '100%',
    color: 'white',
    fontSize: '14px',
    fontFamily: 'arial',
    resize: 'none',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px',
    [theme.breakpoints.down(750)]: {
      borderRadius: '10px',
    },
  },
  inputBoxIcon: {
    position: 'absolute',
    top: '1%',
    right: '3.85%',
    border: 'none',
    background: '#18181a',
    color: '#919192',
    outline: 'none',
    cursor: 'pointer',
    [theme.breakpoints.down(750)]: {
      top: '2%',
      right: '1.3%',
    },
  },
  typing: {
    borderRadius: '0px 0px 7px 7px ',
    color: '#919192',
    position: 'absolute',
    bottom: '2%',
    left: '0%',
    background: '#232325',
    width: '100%',
    paddingLeft: '10px',
    height: '15px',
  },
  activeinputBoxIcon: {
    color: 'white',
  },
}));

export default function ChatInput({ text, typing, handleEnter, handleSubmit, handleTextChange }: ChatInputProps) {
  const classes = useStyles();
  return (
    <div className={typing ? classes.inputBoxContainer : `${classes.inputBoxBackground} ${classes.inputBoxContainer}`}>
      {/* <form onSubmit={e=>{
        e.preventDefault();
        handleSubmit();
        
      }}> */}
      <div className="ic">
        <textarea
          placeholder="Secure Message"
          value={text}
          rows={5}
          className={classes.inputBox}
          // className={typing ? classes.inputBoxTyping : classes.inputBox}
          onKeyPress={e => handleEnter(e)}
          onChange={e => handleTextChange(e)}
        />
        <button
          className={text.length === 0 ? classes.inputBoxIcon : `${classes.inputBoxIcon} ${classes.activeinputBoxIcon}`}
          onClick={handleSubmit}
        >
          <ArrowUpwardIcon />
        </button>
      </div>
      {/* </form> */}
      <div className={classes.typing}>{typing}</div>
    </div>
  );
}
