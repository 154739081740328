import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { CreateRoom } from './types';
import { RootState } from './store';
// import { db } from '../firebase/db.config';
// import { joinWaitingList } from '../utils/joinToWaitingList';
// import firebase from 'firebase';

export const initialState: CreateRoom = {
  userName: '',
  roomName: '',
  agreedTerms: false,
  authToken: '',
  ipAddress: '',
  roomType: 'small',
  devicesSelected: false,
};

// middlewares
// export const CREATE_ROOM = createAsyncThunk(
//   "createRoom/CREATE_ROOM",
//   async (params, {getState}) => {
//     try {
//       const store :  ReturnType<typeof getState> = getState();
//       const {roomName, userName} = selectCreateRoomState(store as RootState);

//       const res = await db
//         .collection('roomDetails')
//         .where('roomName', '==', roomName)
//         .get();
//       var clientDate = new Date();
//       var currentDate = clientDate.getDate();
//       var currentMonth = clientDate.getMonth();
//       var idDocAdded : string | null =null;
//       var createNewDocument;
//       var id, exists, docDate, docMonth;
//       res.docs.forEach(item => {
//         id = item.id;
//         exists = item.exists;
//         docDate = item
//           .data()
//           .timeStamp.toDate()
//           .getDate();
//         docMonth = item
//           .data()
//           .timeStamp.toDate()
//           .getMonth();
//         if (exists && currentDate === docDate && currentMonth === docMonth) {
//           idDocAdded = id;
//           createNewDocument = false;
//         }
//       });

//       if (createNewDocument === false) {
//       } else {
//         const newRoom = await db.collection('roomDetails').add({
//           roomName: roomName,
//           timeStamp: firebase.firestore.FieldValue.serverTimestamp(),
//         });
//         idDocAdded = newRoom.id;
//       }
//       if (userName === 'doctor') {
//         getToken(userName, roomName).then(token => {
//           connect(token);
//         });
//         await db
//           .collection('roomDetails')
//           .doc(idDocAdded || undefined)
//           .update({
//             hostName: userName,
//             roomType,
//           });
//         // setShowWaitingRoom(false);
//       } else {
//         await joinWaitingList({participantName:userName ,roomId:idDocAdded})
//         // setShowWaitingRoom(true);
//       }
//     } catch (error) {
//       console.log('ERROR fetchToken ', error);
//     }
//   }
//)

export const roomSlice = createSlice({
  name: 'createRoom',
  initialState: { ...initialState },
  reducers: {
    SET_ROOM_DETAILS: (state, { payload }: PayloadAction<Partial<CreateRoom>>) => {
      state.agreedTerms = payload.agreedTerms || state.agreedTerms;
      state.roomName = payload.roomName || state.roomName;
      state.userName = payload.userName || state.userName;
      state.authToken = payload.authToken || state.authToken;
      state.ipAddress = payload.ipAddress || state.ipAddress;
      state.roomType = payload.roomType || state.roomType;
      state.devicesSelected = payload.devicesSelected || state.devicesSelected;
    },
    CLEAR_STATE: (state, action) => {
      Object.assign(state, initialState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { SET_ROOM_DETAILS, CLEAR_STATE } = roomSlice.actions;

export default roomSlice.reducer;

// selectors
export const selectCreateRoomState = (store: RootState) => store.createRoom;
