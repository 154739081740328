import { LocalVideoTrack } from 'twilio-video';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

const useLocalVideoToggle = () => {
  const [isPublishing, setIspublishing] = useState(false);
  const previousDeviceIdRef = useRef<string>();

  const {
    room: { localParticipant },
    localTracks,
    getLocalVideoTrack,
    removeLocalVideoTrack,
    onError,
    activeDeviceId,
    setActiveDeviceId,
  } = useVideoContext();
  const isVideoMuted = useMemo(() => activeDeviceId === 'muted', [activeDeviceId]);
  const videoTrack = localTracks.find(track => track.name.includes('camera')) as LocalVideoTrack;

  const toggleVideo = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        setIspublishing(false);
        setActiveDeviceId('muted');
        removeLocalVideoTrack();
      } else {
        setIspublishing(true);
        getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
          .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
          .catch(onError)
          .finally(() => setIspublishing(false));
      }
    }
  }, [videoTrack, localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  const turnOnVideo = useCallback(() => {
    if (!isPublishing) {
      setIspublishing(true);
      getLocalVideoTrack({ deviceId: { exact: previousDeviceIdRef.current } })
        .then((track: LocalVideoTrack) => localParticipant?.publishTrack(track, { priority: 'low' }))
        .catch(onError)
        .finally(() => setIspublishing(false));
    }
  }, [localParticipant, getLocalVideoTrack, isPublishing, onError, removeLocalVideoTrack]);

  const turnOffVideo = useCallback(() => {
    if (!isPublishing) {
      if (videoTrack) {
        previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
        const localTrackPublication = localParticipant?.unpublishTrack(videoTrack);
        // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
        localParticipant?.emit('trackUnpublished', localTrackPublication);
        removeLocalVideoTrack();
        setIspublishing(false);
      }
    }
  }, [localParticipant, isPublishing, removeLocalVideoTrack]);

  //  [!!videoTrack, toggleVideoEnabled, isPublishin  , turnOnVideo, turnOffVideo] as const;

  return {
    hasVideoTrack: !!videoTrack,
    toggleVideo,
    cameraTurnedOn: isVideoMuted,
    turnOnVideo,
    turnOffVideo,
    setActiveDeviceId,
    activeDeviceId,
  };
};

export default useLocalVideoToggle;
