import React, { createContext, useContext, useReducer, useState } from 'react';
import { GetTelehealthToken, RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import useFirebaseAuth from './useFirebaseAuth/useFirebaseAuth';
import usePasscodeAuth from './usePasscodeAuth/usePasscodeAuth';
import { User } from 'firebase';
import { axiosConfig } from '../axiosBaseURL';
import { db, functions } from '../firebase/db.config';
import { firebaseHelper } from '../firebaseHelper';
import { telehealthtoken } from '../firebase/cloudServices';

export interface StateContextType {
  error: TwilioError | null;
  setError(error: TwilioError | null): void;
  getToken(name: string, room: string, passcode?: string): Promise<GetTelehealthToken>;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

/*
  The 'react-hooks/rules-of-hooks' linting rules prevent React Hooks fron being called
  inside of if() statements. This is because hooks must always be called in the same order
  every time a component is rendered. The 'react-hooks/rules-of-hooks' rule is disabled below
  because the "if (process.env.REACT_APP_SET_AUTH === 'firebase')" statements are evaluated
  at build time (not runtime). If the statement evaluates to false, then the code is not
  included in the bundle that is produced (due to tree-shaking). Thus, in this instance, it
  is ok to call hooks inside if() statements.
*/
export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);

  let contextValue = {
    error,
    setError,
    isFetching,
    activeSinkId,
    setActiveSinkId,
    settings,
    dispatchSetting,
  } as StateContextType;

  // if (process.env.REACT_APP_SET_AUTH === 'firebase') {
  //   contextValue = {
  //     ...contextValue,
  //     ...useFirebaseAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //   };
  // } else if (process.env.REACT_APP_SET_AUTH === 'passcode') {
  //   contextValue = {
  //     ...contextValue,
  //     ...usePasscodeAuth(), // eslint-disable-line react-hooks/rules-of-hooks
  //   };
  // } else {
  contextValue = {
    ...contextValue,
    getToken: async (userName, roomName) => {
      const headers = new window.Headers();
      var idDocAdded = await firebaseHelper(roomName);
      var rmtype;
      await db
        .collection('roomDetails')
        .doc(idDocAdded)
        .get()
        .then(function(doc) {
          if (doc.exists) {
            rmtype = doc.data()?.roomType;
          }
        })
        .catch(function(error) {
          console.log('Error getting document:', error);
        });
      // const params = new window.URLSearchParams({ userName, roomName });
      var roomSize = rmtype;
      // return axiosConfig.post(`/token?${params}`, roomSize).then(res => res.data);
      // return axiosConfig.post(`/telehealthtoken?${params}`, roomSize).then(res => res.data);

      const params = {
        // userName,
        roomName,
        roomSize,
        userName,
      };
      const fbResponse = await telehealthtoken(params);
      return fbResponse.data as GetTelehealthToken;
    },
  };

  const getToken: StateContextType['getToken'] = (name, room) => {
    setIsFetching(true);
    return contextValue
      .getToken(name, room)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  };

  return <StateContext.Provider value={{ ...contextValue, getToken }}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
