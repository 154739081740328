///
// src/components/tetris-board.tsx

// Import React
import * as React from 'react';
import mq from './media-queries';
import styled from 'styled-components';
import useHeight from '../../hooks/useHeight/useHeight';

// Define props for TetrisBoard component
type TetrisBoardProps = {
  field: any[];
  gameOver: boolean;
  score: number;
  level: number;
  rotate: number;
};
const GameOverStyle = styled.div`
   {
    ${mq.MOBILE} {
      display: none;
    }
  }
`;
// Create TetrisBoard component
const TetrisBoard: React.FC<TetrisBoardProps> = props => {
  // Create board rows
  let rows: any[] = [];
  // const height:any = useHeight();
  const height: any = window.innerHeight;
  const width: any = window.innerWidth;

  if (width > 1440) {
    var paddingBox = Math.abs((0.85 * height) / 40);
  } else if (width > 750) {
    if (height > width) {
      var paddingBox = Math.abs((width - 240) / 40);
    } else {
      var paddingBox = Math.abs((0.75 * height) / 40);
    }
  } else {
    if (height > 800) {
      var paddingBox = Math.abs((0.68 * height) / 40);
    } else if (height > 700) {
      var paddingBox = Math.abs((0.65 * height) / 40);
    } else if (height > 600) {
      var paddingBox = Math.abs((0.6 * height) / 40);
    } else {
      var paddingBox = Math.abs((0.52 * height) / 40);
    }
  }

  props.field.forEach((row, index) => {
    // Create board columns
    const cols = row.map((column: any, index: number) => (
      <div className={`col-${column}`} key={index} style={{ padding: paddingBox }} />
    ));

    rows.push(
      <div className="tetris-board__row" key={index}>
        {cols}
      </div>
    );
  });

  return (
    <div className="tetris-board">
      {/* Game info */}
      <div className="tetris-board__info">
        <div className="tetris-board__text">Level: {props.level}</div>

        <div className="tetris-board__text">Score: {props.score}</div>

        {props.gameOver && (
          <>
            <div className="tetris-board__text">
              <strong>Game Over</strong>
            </div>
            <GameOverStyle className="tetris-board__text">
              Press <strong>Enter</strong> <div>to play again</div>
            </GameOverStyle>
          </>
        )}
      </div>

      {/* Game board */}
      <div className="tetris-board__board">{rows}</div>
    </div>
  );
};

export default TetrisBoard;
