import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase/db.config';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    [theme.breakpoints.down(750)]: {
      display: 'flex',
    },
  },
  options: {
    background: '#18181a',
    color: '#919192',
    marginBottom: '-8px',
    marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    padding: '8px',
    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  text: {
    color: 'white',
    width: '100%',
    [theme.breakpoints.down(750)]: {
      width: '60%',
      margin: '0 auto',
    },
  },
  icon: {
    fill: 'white',
  },
}));
function YoutubeDropdown({ handleYoutubePlaylists }: { handleYoutubePlaylists?: any }) {
  const classes = useStyles();
  const [currentPlayListId, setCurrentPlayListId] = useState<string>('PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG');
  const [playLists, setPlayLists] = useState([]);
  useEffect(() => {
    const fetchPlaylists = async () => {
      await db
        .collection('telehealthConfig')
        .doc('MOi779jRH4zz9VSmiUvO')
        .get()
        .then(resp => {
          var playlist = resp.data()?.youtubePlaylists;
          setPlayLists(playlist);
        })
        .catch(err => console.log(err));
    };
    fetchPlaylists();
  }, []);
  return (
    <div className={classes.container}>
      <Select
        onChange={e => {
          setCurrentPlayListId(e.target.value as string);
          handleYoutubePlaylists(e.target.value as string);
        }}
        value={currentPlayListId || ''}
        variant="outlined"
        className={classes.text}
        inputProps={{
          classes: {
            icon: classes.icon,
          },
        }}
      >
        {playLists?.map((playList: any) => (
          <MenuItem value={playList.playListId} key={playList.playListId} className={classes.options}>
            {playList.label}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
}

export default YoutubeDropdown;
