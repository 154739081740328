import firebase from 'firebase';
import { db, functions } from '../firebase/db.config';
import { SessionDetails, AccountDetails } from '../store/types';
import { ROOM_STATUS } from './constants';
import { generateRoomDocumentModel } from './helper';

const endSession = functions.httpsCallable('endVideoSession');

type EndVideoSessionParamsType = {
  roomId: string;
  roomType: string;
  roomName: string;
};

export const endVideoSession = async ({
  roomId,
  roomName,
  roomType,
  activeParticipants,
  waitingParticipants,
}: Pick<SessionDetails, 'activeParticipants' | 'roomId' | 'roomName' | 'roomType' | 'waitingParticipants'>) => {
  try {
    if (!roomId) throw new Error('Invalid params');

    const params = {
      roomName,
      roomType,
    };
    const toBeupdated: Partial<SessionDetails> = {
      activeParticipants: [],
      allowedParticipants: [],
      status: ROOM_STATUS.ENDED,
      waitingParticipants: [...waitingParticipants, ...activeParticipants],
    };
    await db
      .collection('roomDetails')
      .doc(roomId)
      .update(toBeupdated);
    await endSession(params);
    return;
  } catch (error) {
    if (error instanceof Error) {
      console.log('ERROR : ', error?.message || error);
    }
    return;
  }
};

export const leaveVideoSession = async ({ roomId, userId }: { roomId: string; userId: string }) => {
  try {
    if (!roomId || !userId) throw new Error('Invalid params');

    const toBeupdated = {
      waitingParticipants: firebase.firestore.FieldValue.arrayRemove(userId),
      allowedParticipants: firebase.firestore.FieldValue.arrayRemove(userId),
      activeParticipants: firebase.firestore.FieldValue.arrayRemove(userId),
    };
    await db
      .collection('roomDetails')
      .doc(roomId)
      .update(toBeupdated);
    // await endSession(params);
    return;
  } catch (error) {
    if (error instanceof Error) {
      console.log('ERROR : ', error?.message || error);
    }
    return;
  }
};
