import { useState, useEffect } from 'react';

export function useDevices() {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  useEffect(() => {
    // navigator.mediaDevices.getUserMedia({ audio: true, video: true });
    setAllDevices();
    navigator.mediaDevices.addEventListener('devicechange', setAllDevices);
    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', setAllDevices);
    };
  }, []);

  const setAllDevices = async () => {
    const allDevices = await getAllDevices();
    const devices = [];
    if (allDevices && allDevices.length > 0) {
      devices.push(...allDevices);
    }
    setDevices(devices);
  };

  return devices;
}

export function useAudioInputDevices() {
  const devices = useDevices();
  return devices.filter(device => device.kind === 'audioinput');
}

export function useVideoInputDevices() {
  const devices = useDevices();
  let videoDevices: MediaDeviceInfo[] = devices.filter(device => device.kind === 'videoinput');

  // if(videoDevices.length<=0)
  //   videoDevices.push({
  //     deviceId:"1234",
  //     groupId:"1234543",
  //     label:"System Default Camera",
  //     kind:"videoinput",
  //     toJSON:()=>{}
  //   } as MediaDeviceInfo)

  return videoDevices;
}

export function useAudioOutputDevices() {
  const devices = useDevices();
  let audioDevices: MediaDeviceInfo[] = devices.filter(device => device.kind === 'audiooutput');
  if (audioDevices.length === 0) {
    const defaultDevice: MediaDeviceInfo = {
      deviceId: '001100',
      label: 'System default speaker device',
      groupId: '',
      kind: 'audiooutput',
      toJSON: () => '',
    };
    audioDevices = [{ ...defaultDevice }];
  }
  return audioDevices;
}

type MediaPermissions = {
  audio: boolean;
  video: boolean;
};
export function useMediaPermission() {
  const [state, setState] = useState<MediaPermissions>({
    audio: false,
    video: false,
  });

  useEffect(() => {
    navigator.mediaDevices.ondevicechange = () => checkPermissions();
    checkPermissions();
    return () => {
      navigator.mediaDevices.ondevicechange = null;
    };
  }, []);

  const checkPermissions = async () => {
    try {
      const cameraPermission = await checkCameraPermission();
      const audioPermission = await checkAudioPermission();
      console.log('CMWRR---', cameraPermission, audioPermission);

      setState(({ ...o }) => {
        o.audio = Boolean(audioPermission);
        o.video = Boolean(cameraPermission);
        return o;
      });
      // const videoPermissions =  await navigator.mediaDevices.getUserMedia({audio: false, video: true});
    } catch (error) {
      console.log('Error : checkPermissions', error);
    }
  };

  return {
    cameraGrantend: state.video,
    micGranted: state.audio,
  };
}

export function useHasAudioInputDevices() {
  const audioDevices = useAudioInputDevices();
  return audioDevices.length > 0;
}

export function useHasVideoInputDevices() {
  const videoDevices = useVideoInputDevices();
  return videoDevices.length > 0;
}

const checkAudioPermission = async () => {
  try {
    let res = await navigator.mediaDevices.getUserMedia({ audio: true });
    return Boolean(res);
  } catch (error) {
    return false;
  }
};
const checkCameraPermission = async () => {
  try {
    let res = await navigator.mediaDevices.getUserMedia({ video: true });
    return Boolean(res);
  } catch (error) {
    if (error instanceof Error) console.log('Permission--', error?.message);

    return false;
  }
};
const getAllDevices = async () => {
  try {
    let res = await navigator.mediaDevices.enumerateDevices();
    return res || [];
  } catch (error) {
    return [];
  }
};
