import React, { useState, useRef } from 'react';
import Button from '@material-ui/core/Button';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import Typography from '@material-ui/core/Typography';
import { Divider, makeStyles, Theme, useMediaQuery } from '@material-ui/core';

import AboutDialog from '../../../AboutDialog/AboutDialog';
import ConnectionOptionsDialog from '../../../ConnectionOptionsDialog/ConnectionOptionsDialog';
import DeviceSelectionDialog from '../../../DeviceSelectionDialog/DeviceSelectionDialog';
import SettingsIcon from '../../../../icons/SettingsIcon';
import { useAppState } from '../../../../state';
import InviteDialog from '../../../InviteDialog/InviteDialog';

const useStyles = makeStyles((theme: Theme) => ({
  settingsButton: {
    margin: '0 0 0 auto',
    display: 'block',
    paddingTop: '1.2em',
  },
  container1: {
    marginTop: '-9px',
    borderTop: '2px solid #919192',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
    '&:hover': {
      backgroundColor: '#323132',
    },
  },
  container2: {
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
    '&:hover': {
      backgroundColor: '#323132',
    },
  },
  container3: {
    marginBottom: '-8px',
    borderBottom: '2px solid #919192',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    background: '#18181a',
    '&:hover': {
      backgroundColor: '#323132',
    },
  },
  text: {
    color: 'white',
  },
}));

export default function SettingsMenu({
  mobileButtonClass,
  handleActiveSpeaker,
  evisit,
  disableAudioVideo,
}: {
  mobileButtonClass?: string;
  handleActiveSpeaker?: any;
  evisit?: boolean;
  disableAudioVideo: boolean;
}) {
  const classes = useStyles();
  const { roomType } = useAppState();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const [menuOpen, setMenuOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);
  const [connectionSettingsOpen, setConnectionSettingsOpen] = useState(false);

  const anchorRef = useRef<HTMLButtonElement>(null);

  return (
    <>
      {/* {isMobile ? (
        <Button
          ref={anchorRef}
           onClick={() => setMenuOpen(true)}
          startIcon={<MoreIcon />}
          className={mobileButtonClass}
        >
          More
        </Button>
      ) : ( */}
      <Button
        ref={anchorRef}
        onClick={() => setMenuOpen(true)}
        startIcon={evisit ? <SettingsIcon white /> : <SettingsIcon />}
        className={evisit ? '' : classes.settingsButton}
      >
        {/* Settings */}
      </Button>
      {/* )} */}
      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: isMobile ? 'left' : 'right',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : -45,
          horizontal: 'center',
        }}
      >
        <MenuItem className={classes.container1} onClick={() => setAboutOpen(true)}>
          <Typography className={classes.text} variant="body1">
            About
          </Typography>
        </MenuItem>
        {!disableAudioVideo && (
          <MenuItem className={classes.container2} onClick={() => setDeviceSettingsOpen(true)}>
            <Typography className={classes.text} variant="body1">
              Audio and Video Settings
            </Typography>
          </MenuItem>
        )}
        {/* {roomType !== 'peer-to-peer' && roomType !== 'go' && ( */}
        {roomType !== 'peer-to-peer' && (
          <MenuItem className={classes.container3} onClick={() => setConnectionSettingsOpen(true)}>
            <Typography className={classes.text} variant="body1">
              Connection Settings
            </Typography>
          </MenuItem>
        )}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
      {handleActiveSpeaker ? (
        <DeviceSelectionDialog
          open={deviceSettingsOpen}
          onClose={() => {
            setDeviceSettingsOpen(false);
            setMenuOpen(false);
          }}
          handleActiveSpeaker={handleActiveSpeaker}
        />
      ) : (
        <DeviceSelectionDialog
          open={deviceSettingsOpen}
          onClose={() => {
            setDeviceSettingsOpen(false);
            setMenuOpen(false);
          }}
        />
      )}

      <ConnectionOptionsDialog
        open={connectionSettingsOpen}
        onClose={() => {
          setConnectionSettingsOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );
}
