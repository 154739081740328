import { ROOM_STATUS, ROOM_TYPE } from '../constants';
import { firestore } from 'firebase';
import getTelehealthDocRefs from '../firebaseServices/getDocumentRefs';
import { UserModal } from './getUserModel';

type TimeStampType = ReturnType<typeof firestore.Timestamp.now>;
// type DocumentData =   firestore.DocumentData
// type DocumentReference< T = DocumentData> =  firestore.DocumentReference<T>
// type DocumentReference =  typeof firestore.FieldValue.

const { roomDocRef } = getTelehealthDocRefs();

type RoomTypesKeys = keyof typeof ROOM_TYPE;
export type RoomTypes = typeof ROOM_TYPE[RoomTypesKeys];
type RoomStatusKeys = keyof typeof ROOM_STATUS;
export type RoomStatus = typeof ROOM_STATUS[RoomStatusKeys];

export interface RoomModal {
  activeParticipants: string[];
  allowedParticipants: string[];
  conversationId: string[] | null;
  hostId: string | null;
  participants: string[] | null;
  roomId?: string;
  roomName: string;
  roomType: RoomTypes;
  status: RoomStatus;
  timeStamp?: TimeStampType | null;
  waitingParticipants: string[];
}

interface CreateRoomModal extends RoomModal {
  roomId: string;
  // hostId : string,
}

const generateRoomDocumentModel = ({
  activeParticipants = [],
  allowedParticipants = [],
  conversationId = null,
  hostId = null,
  participants = [],
  roomId = roomDocRef.doc().id,
  roomName = '',
  roomType = ROOM_TYPE.SMALL,
  status = ROOM_STATUS.INITIATED,
  timeStamp = firestore.Timestamp.now(),
  waitingParticipants = [],
}: RoomModal): CreateRoomModal => {
  return {
    activeParticipants,
    allowedParticipants,
    conversationId,
    hostId,
    participants,
    roomId,
    roomName,
    roomType,
    status,
    timeStamp,
    waitingParticipants,
  };
};

export default generateRoomDocumentModel;
