import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
// import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ChevronDown from '../../icons/ChevronDown';

const useStyles = makeStyles((theme: Theme) => ({
  chatTopHeading: {
    height: '40px',
    backgroundColor: '#323132',
    outline: 'none',
    left: '1%',
    width: '98%',
    padding: '7px',
    color: 'white',
    fontSize: '18px',
    borderRadius: '10px 10px 0px 0px',
    border: '2px solid rgb(50, 49, 50)',
    position: 'relative',
    [theme.breakpoints.down(750)]: {
      left: '1%',
      width: '98%',
    },
  },
  cursor: {
    // cursor: 'pointer',
  },
  topIconSpace: {
    float: 'right',
    cursor: 'pointer',
  },
}));

export default function ChatHeading({ handleRightBtn }: { handleRightBtn?: any }) {
  const classes = useStyles();
  return (
    <div className={`${classes.chatTopHeading} ${classes.cursor}`} onClick={handleRightBtn}>
      Chat{' '}
      <span className={classes.topIconSpace}>
        {/* <KeyboardArrowDownIcon /> */}
        <ChevronDown />
      </span>
    </div>
  );
}
