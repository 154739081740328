import React, { useState, useEffect } from 'react';

export default function Timer() {
  const [finalTimer, setFinalTimer] = useState<string>('');
  const [counter, setCounter] = useState<number>(0);

  const handleCounter = (counter: number) => {
    var a;
    var b, ans;
    while (counter > 59) {
      a = counter / 60;
      b = counter % 60;
      counter = counter % 60;
    }
    if (b) {
      if (a) {
        if (b < 10) ans = Math.floor(a) + ':0' + b;
        else ans = Math.floor(a) + ':' + b;
      } else {
        if (b < 10) ans = '00:0' + b;
        else ans = '00:' + b;
      }
    } else {
      if (a) {
        if (counter < 10) ans = Math.floor(a) + ':0' + counter;
        else ans = Math.floor(a) + ':' + counter;
      } else {
        if (counter < 10) ans = '00:0' + counter;
        else ans = '00:' + counter;
      }
    }
    setFinalTimer(ans);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter(counter + 1);
    }, 1000);
    handleCounter(counter);
    return () => {
      clearInterval(interval);
    };
  });
  // return(
  //   <>
  //     <div>{finalTimer}</div>
  //   </>
  // )
  return finalTimer;
}
