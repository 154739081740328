import React from 'react';

export default function TetrisIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100px" height="80px" className="cls-1" viewBox="0 0 93.24 62.49">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <rect className="cls-1" x="31.25" y="0.5" width="30.75" height="30.75" />
          <rect className="cls-1" x="31.25" y="31.25" width="30.75" height="30.75" />
          <rect className="cls-1" x="61.99" y="0.5" width="30.75" height="30.75" />
          <rect className="cls-1" x="0.5" y="0.5" width="30.75" height="30.75" />
        </g>
      </g>
    </svg>
  );
}
