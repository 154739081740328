import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './loader.m.css';

const Loader = ({ size, color }) => {
  const styles = cx('loader', {
    extrasmall: size === 'extrasmall',
    small: size === 'small',
    midsmall: size === 'midsmall',
    medium: size === 'medium',
    large: size === 'large',
    white: color === 'white',
    lightblue: color === 'lightblue',
    purewhite: color === 'purewhite',
  });
  return (
    <div className="wrapperLoader">
      <div className={styles} />
    </div>
  );
};

Loader.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
};

Loader.defaultProps = {
  size: 'small',
  color: 'white',
};

export default Loader;
