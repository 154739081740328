import React, { useEffect, useCallback, useState } from 'react';
import { FormControl, MenuItem, Typography, Select, FormHelperText } from '@material-ui/core';
import { useAppState } from '../../../state';
import { useAudioOutputDevices, useMediaPermission } from '../../../hooks/deviceHooks/deviceHooks';
import { makeStyles } from '@material-ui/core/styles';
// import useSound from 'use-sound';
import testAudioFile from '../../../sounds/testAudio.mp3';

const useStyles = makeStyles({
  preview: {
    width: '300px',
    maxHeight: '200px',
    margin: '0.5em auto',
    '& video': {
      maxHeight: '200px',
    },
  },
  titleHeader: {
    color: '#919192',
  },
  text: {
    color: 'white',
  },
  options: {
    background: '#18181a',
    color: '#919192',
    marginBottom: '-8px',
    marginTop: '-8px',
    borderLeft: '2px solid #919192',
    borderRight: '2px solid #919192',
    padding: '8px',

    '&:hover': {
      backgroundColor: '#323132',
      color: 'white',
    },
    '&:first-child': {
      borderTop: '2px solid #919192',
    },
    '&:last-child': {
      borderBottom: '2px solid #919192',
    },
  },
  select: {
    color: 'white',
    width: '100%',
  },
  icon: {
    fill: 'white',
  },
  test: {
    position: 'absolute',
    float: 'right',
    color: '#4284f3',
    fontSize: '15px',
    cursor: 'pointer',
    background: 'transparent',
    outline: 'none',
    border: '0px',
    zIndex: 9999,
    // width: '35px',
    marginRight: '6px',
    right: '12px',
    top: '-28px',
    paddingRight: '0px',
    '&.disabled': {
      color: 'var(--bs-gray-600)',
    },
  },
  inputSelect: {
    display: 'flex',
    width: '100%',
  },
});
export default function AudioOutputList({ handleActiveSpeaker }: { handleActiveSpeaker?: any }) {
  const classes = useStyles();
  const testAudio = new Audio(testAudioFile);

  const [playbackRate, setPlaybackRate] = useState(1);
  const audioOutputDevices = useAudioOutputDevices();
  const { micGranted } = useMediaPermission();
  const { activeSinkId, setActiveSinkId } = useAppState();
  const [audioPlaying, setAudioPlaying] = useState<boolean>(false);
  // use Audio constructor to create HTMLAudioElement

  const activeOutputLabel = audioOutputDevices.find(device => device.deviceId === activeSinkId)?.label;
  const testAudioRef = React.useRef<HTMLAudioElement>(testAudio);

  // const [play] = useSound(sampleAudio, {
  //   playbackRate,
  //   volume: 1,
  // });
  // load audio file on component load
  useEffect(() => {
    testAudioRef.current.load();

    if (handleActiveSpeaker) {
      handleActiveSpeaker(activeOutputLabel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = useCallback(
    async (e: any) => {
      e.preventDefault();
      setAudioPlaying(true);
      setPlaybackRate(playbackRate);
      testAudioRef?.current?.pause();
      // testAudioRef?.current?.currentTime.
      testAudioRef?.current?.play();
    },
    [testAudioRef]
  );

  useEffect(() => {
    if (!activeSinkId) {
      setActiveSinkId(audioOutputDevices[0].deviceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioOutputDevices]);

  useEffect(() => {
    if (activeSinkId && testAudioRef && micGranted && testAudioRef?.current?.setSinkId) {
      // @ts-ignore
      testAudioRef?.current?.setSinkId(activeSinkId || '');
    }
  }, [activeSinkId, micGranted]);

  // function replaceTrack(newDeviceId: string) {
  //   window.localStorage.setItem(SELECTED_AUDIO_OUTPUT_KEY, newDeviceId);
  //   localAudioTrack?.restart({ deviceId: { exact: newDeviceId } });
  // }

  return (
    <div className={classes.inputSelect}>
      {/* {audioOutputDevices.length > 1 ? ( */}
      <FormControl fullWidth error={!micGranted} disabled={!micGranted}>
        {/* <Typography className={classes.titleHeader} variant="subtitle2" gutterBottom>
            Audio Output
          </Typography> */}
        <div>
          <Select
            onChange={e => setActiveSinkId(e.target.value as string)}
            value={activeSinkId}
            variant="outlined"
            className={classes.select}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}
          >
            {micGranted &&
              audioOutputDevices.map(device => (
                <MenuItem value={device.deviceId} key={device.deviceId} className={classes.options}>
                  {device.label}
                </MenuItem>
              ))}
          </Select>
          <FormHelperText>
            {!micGranted &&
              'You have denied microphone access. Please adjust your browser settings to allow microphone access.'}
          </FormHelperText>
          {/* <audio ref={testAudioRef} /> */}

          <button
            disabled={!micGranted}
            className={`${classes.test} ${!micGranted && 'disabled'}`}
            onClick={e => handleClick(e)}
          >
            {audioPlaying ? 'Playing...' : 'Test'}
          </button>
        </div>
      </FormControl>
      {/* ) : (
        <>
          <Typography className={classes.titleHeader} variant="subtitle2">
            Audio Output
          </Typography>
          <div>
            <Typography className={classes.text}>{activeOutputLabel || 'System Default Audio Output'}</Typography>
            <button className={classes.test} onClick={e => handleClick(e)}>
              {audioPlaying ? 'Playing...' : 'Test'}
            </button>
          </div>
        </>
      )} */
      /* <button className={classes.test} onClick={e => handleClick(e)}>
        {audioPlaying ? 'Playing...' : 'Test'}
      </button> */}
    </div>
  );
}
