import React, { PropsWithChildren } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, Theme } from '@material-ui/core';
import SCREEM from '../../icons/SCREEM';

interface AboutDialogProps {
  open: boolean;
  onClose(): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  // container1: {
  //    background: '#18181a',
  //    borderBottom: '2px solid #323132',
  //    borderLeft: '2px solid #027AC5',
  //    borderRight: '2px solid #027AC5',
  //    borderTop: '2px solid #027AC5',
  //    color : '#aaa'
  // },
  // container2: {
  //   // borderLeft : '2px solid #323132',
  //   //   borderRight : '2px solid #323132',
  //   borderLeft: '2px solid #027AC5',

  //   borderRight: '2px solid #027AC5',

  //     background: '#18181a',
  // },
  // container3: {
  //   background: '#18181a',
  //   borderTop: '2px solid #323132',
  //   borderBottom: '2px solid #027AC5',
  //   borderLeft: '2px solid #027AC5',
  //   borderRight: '2px solid #027AC5',

  // },
  container1: {
    background: '#18181a',
    border: '2px solid #323132',
    color: '#4284f3',
    fontFamily: 'Arial',
  },
  container2: {
    borderLeft: '2px solid #323132',
    borderRight: '2px solid #323132',
    background: '#18181a',
    fontFamily: 'Arial',
  },
  container3: {
    background: '#18181a',
    border: '2px solid #323132',
    fontFamily: 'Arial',
  },
  text: {
    color: '#aaa',
    fontWeight: 'bolder',
  },
  textMiddle: {
    color: '#aaa',
  },
  space: {
    marginBottom: '-5px',
  },
  textLower: {
    color: '#aaa',
    fontWeight: 'bolder',
    marginBottom: '0px',
  },
  symbol: {
    color: '#aaa',
    marginBottom: '-20px',
    '& svg': {
      fill: '#aaa',
      height: '52px',
    },
  },
  btn: {
    color: 'white',
    background: '#4284f3',
  },
  smallCaps: {
    fontVariant: 'small-caps',
    fontSize: '13px',
  },
}));

function AboutDialog({ open, onClose }: PropsWithChildren<AboutDialogProps>) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xs">
      <DialogTitle className={classes.container1}>About</DialogTitle>
      {/* <Divider /> */}
      <DialogContent className={classes.container2}>
        <DialogContentText className={classes.text}>
          endlink<sup>®</sup> TeleHealth and TeleShare<sup style={{ fontSize: '7px' }}>TM</sup>
        </DialogContentText>
        <DialogContentText className={`${classes.textMiddle} ${classes.space}`}>Version 0.2.4</DialogContentText>
        <DialogContentText className={classes.textMiddle}>
          2014-2021 Endlink<sup>®</sup> LLC
        </DialogContentText>
        <DialogContentText className={classes.textLower}>Envisioned and Executed with</DialogContentText>
        <DialogContentText className={classes.symbol}>
          <SCREEM />
        </DialogContentText>
        <DialogContentText className={`${classes.textLower} ${classes.smallCaps}`}>
          Industry-Agnostic Action Framework
        </DialogContentText>
      </DialogContent>
      {/* <Divider /> */}
      <DialogActions className={classes.container3}>
        <Button className={classes.btn} onClick={onClose} variant="contained" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AboutDialog;
