import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, Divider } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  message: {
    color: 'white',
    background: '#2a2829',
    padding: '9px',
    wordBreak: 'break-word',
  },
  sender: {
    fontWeight: 'bolder',
    fontSize: '15px',
  },
  time: {
    float: 'right',
  },
  messageText: {},
  hyperlink: {
    color: '#4284f3',
  },
}));

export default function ChatItem({ item }: { item?: any }) {
  const classes = useStyles();
  const date = new Date();
  const [currentTime, setCurrentTime] = useState<number>(0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    var time = date.getUTCHours() * 60 + date.getUTCMinutes();
    setCurrentTime(time);
  });
  const urlify = (text: any) => {
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    var href: any;
    var hyperlink: any;
    text.replace(urlRegex, (url: any, b: any, c: any) => {
      var url2 = c === 'www.' ? 'http://' + url : url;
      hyperlink = url;
      href = url2;
    });
    if (hyperlink) {
      var index = text.search(hyperlink);
      return (
        <span>
          {text.substr(0, index)}{' '}
          <a href={href} rel="noopener noreferrer" className={classes.hyperlink} target="_blank">
            {hyperlink}
          </a>
          {text.substr(index + hyperlink.length, text.length)}
        </span>
      );
    } else return text;
  };
  return (
    <>
      <div className={classes.message}>
        <div>
          <span className={classes.sender}>{item.username}</span>
          <span className={classes.time}>
            {currentTime - item.time}
            {currentTime - item.time <= 1 ? ' minutes ago' : ' minutes ago'}{' '}
          </span>
        </div>
        <div className={classes.messageText}>{urlify(item.message)}</div>
      </div>
      <Divider light={true} />
    </>
  );
}
