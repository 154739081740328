import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { Button } from '@material-ui/core';
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: 'var(--bs-gray-900)',
    color: 'white',
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    maxWidth: '500px',
  },
  header: {
    color: '',
  },
  warningBtn: {
    backgroundColor: '#4284f3',
    color: '#ffff',
  },
}));

export default function TransitionsModal({ open = false, handleClose = () => {} }) {
  const classes = useStyles();

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <header className={'d-flex align-items-lg-end mb-4  ' + classes.header}>
            <WarningIcon fontSize={'large'} className="me-2" style={{ color: 'var(--bs-warning)' }} />
            <h4 id="transition-modal-title" className="mb-0">
              {' '}
              Warning
            </h4>
          </header>
          <p id="transition-modal-description">
            This browser is not supported. Please be aware that if you continue, the app may not work as expected. For a
            list of supported browsers,{' '}
            <a target="_blank" href="https://www.twilio.com/docs/video/javascript#supported-browsers">
              click here
            </a>
          </p>
          <Button onClick={handleClose} variant="contained" className={classes.warningBtn}>
            I Understand
          </Button>
        </div>
      </Fade>
    </Modal>
  );
}
