import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CreateRoom } from '../../../store/types';
import { SET_ROOM_DETAILS, initialState, selectCreateRoomState, CLEAR_STATE } from '../../../store/createRoom';
import { RootState } from '../../../store/store';
import { selectAccount } from '../../../store/userDetails';
import { selectLoaders } from '../../../store/loaders';

export default function useJoinRoom() {
  const dispatch = useDispatch();
  const joinerDetails = useSelector((store: RootState) => selectCreateRoomState(store));
  const {
    userName: joinerName,
    agreedTerms: joinerAgreedTerms,
    roomName: joiningRoomName,
  } = useSelector((store: RootState) => selectCreateRoomState(store));
  const isJoinerHost = useMemo(() => joinerName === 'doctor', [joinerName]);
  const { userName: accountName, userId: accountId } = useSelector((store: RootState) => selectAccount(store));
  const { userJoining: isUserJoining } = useSelector((store: RootState) => selectLoaders(store));

  // set userDetail in createRoom state
  const setJoinerDetails = (params: Partial<CreateRoom>) => {
    dispatch(SET_ROOM_DETAILS(params));
  };

  const clearState = () => {
    dispatch(CLEAR_STATE({}));
  };

  return {
    setJoinerDetails,
    joinerDetails,
    joiningRoomName,
    joinerName,
    isJoinerHost,
    accountId,
    accountName,
    isUserJoining,
    clearState,
  };
}
