import { createSlice } from '@reduxjs/toolkit';
import { App } from './types';

const initialState: App = {
  isLoggedIn: false,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
});

// Action creators are generated for each case reducer function
// export const {  } = counterSlice.actions

export default appSlice.reducer;
