import React from 'react';
import { makeStyles, Button, Theme } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import icon from '../../icons/icon.png';
import logo from '../../icons/logo.png';
import loader from '../../icons/ThreeDotLoader.gif';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    color: 'white',
    fontFamily: 'arial',
    background: '#232325',
    display: 'flex',
    flexDirection: 'column',
  },
  textContainer: {
    width: '55%',
    margin: '5% auto',
    [theme.breakpoints.down(600)]: {
      width: '65%',
    },
    [theme.breakpoints.down(300)]: {
      width: '85%',
    },
  },
  heading: {
    fontVariant: 'small-caps',
    fontSize: '20px',
    textAlign: 'center',
  },
  text: {
    margin: '30px 0px',
    fontSize: '15px',
    display: 'flex',
    alignItems: 'baseline',
    '& svg': {
      fontSize: '11px',
    },
  },
  button: {
    display: 'flex',
    margin: '0 auto',
    background: '#4284f3',
    color: 'white',
    borderRadius: '10px',
  },
  icon: {},
  logoContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '20px',
  },
  mainLogo: {
    width: '400px',
    height: '9%',
    // bottom: '1%',
    // right: '10px',
    // position: 'absolute',
    [theme.breakpoints.down(900)]: {
      width: '45%',
    },
    [theme.breakpoints.down(250)]: {
      position: 'relative',
      margin: 'auto',
    },
  },
  loaderWrapper: {
    position: 'absolute',
    top: '40%',
    transform: 'translate(-50%, -50%)',
    left: '50%',
    zIndex: 1,
    display: 'flex',
    alignItems: 'center',
  },
  loader: {
    height: '10px',
    width: 'auto',
    paddingLeft: '8px',
    marginTop: '6px',
  },
  outerDiv: {
    height: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  bulletIcon: {
    paddingLeft: '5px',
  },
  disable: {
    filter: 'blur(2px)',
  },
}));

export default function PHIConsent({ fetchToken, disableBtn }: { fetchToken: any; disableBtn?: any }) {
  const classes = useStyles();
  const handleButtonClick = () => {
    fetchToken();
  };
  return (
    <>
      {disableBtn && (
        <div className={classes.loaderWrapper}>
          <span style={{ color: 'white', fontSize: '36px' }}>Preparing your Room</span>
          <img src={loader} alt="loader..." className={classes.loader} />
        </div>
      )}
      <div className={`${classes.wrapper} ${disableBtn ? classes.disable : ''}`}>
        <div className={classes.textContainer}>
          <p className={classes.heading}>eVisit Terms & Conditions</p>
          <p className={classes.text}>
            <FiberManualRecordIcon />
            <span className={classes.bulletIcon}>
              If you are a patient, by joining this session you consent to discuss your protected health information
              (PHI) with participants
            </span>
          </p>
          <p className={classes.text}>
            <FiberManualRecordIcon />{' '}
            <span className={classes.bulletIcon}>
              If you are the doctor or staff member hosting this session, by joining this session you consent to follow
              the standard confidentiality rules governing your business
            </span>
          </p>
          <p className={classes.text}>
            <FiberManualRecordIcon />
            <span className={classes.bulletIcon}>
              {' '}
              If you are another participant, by joining this session you agree to keep strictly confidential and not
              disclose, or cause or permit to be disclosed, to any person or entity, the information disclosed in this
              session
            </span>
          </p>
        </div>
        <div>
          <Button
            variant="contained"
            data-cy-join-now
            onClick={handleButtonClick}
            className={classes.button}
            disabled={disableBtn}
          >
            <img src={icon} style={{ paddingRight: '8px' }} height="20px" alt="icon" className={classes.icon} />
            Accept Terms & Start eVisit
          </Button>
        </div>
        <div className={classes.outerDiv}>
          <div className={classes.logoContainer}>
            <img src={logo} alt="main logo" className={classes.mainLogo} />
          </div>
        </div>
      </div>
    </>
  );
}
