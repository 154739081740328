import React from 'react';

export const GameControl: React.SFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.8 47.8">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="23.9" cy="23.9" r="22.9" />
        <path
          className="cls-2"
          d="M28,14.37V33.43a1.48,1.48,0,0,1-2.53,1L15.9,25a1.48,1.48,0,0,1,0-2.1l9.53-9.53A1.48,1.48,0,0,1,28,14.37Z"
        />
      </g>
    </g>
  </svg>
);
export const NewGame: React.SFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.8 47.8">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="23.9" cy="23.9" r="22.9" />
        <path
          className="cls-2"
          d="M33.86,23.88a10,10,0,0,1-16.21,7.77,1,1,0,0,1-.07-1.43l.45-.45a1,1,0,0,1,1.28-.08,7.39,7.39,0,1,0-.47-11.17l2,2a.65.65,0,0,1-.46,1.1H14.58a.64.64,0,0,1-.64-.64V15.17a.64.64,0,0,1,1.1-.45l2,2a10,10,0,0,1,16.84,7.18ZM26.59,27l.4-.5a1,1,0,0,0-.17-1.36l-1.64-1.27V19.72a1,1,0,0,0-1-1h-.64a1,1,0,0,0-1,1v5.45l2.63,2A1,1,0,0,0,26.59,27Z"
        />
      </g>
    </g>
  </svg>
);

export const GamePause: React.SFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.8 47.8">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="23.9" cy="23.9" r="22.9" />
        <path
          className="cls-2"
          d="M21.4,30.89h-3a1.5,1.5,0,0,1-1.49-1.5v-11a1.49,1.49,0,0,1,1.49-1.49h3a1.5,1.5,0,0,1,1.5,1.49v11A1.5,1.5,0,0,1,21.4,30.89Zm9.49-1.5v-11a1.5,1.5,0,0,0-1.5-1.49h-3a1.5,1.5,0,0,0-1.5,1.49v11a1.5,1.5,0,0,0,1.5,1.5h3A1.5,1.5,0,0,0,30.89,29.39Z"
        />
      </g>
    </g>
  </svg>
);

export const GameRotate: React.SFC = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.8 47.8">
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <circle className="cls-1" cx="23.9" cy="23.9" r="22.9" />
        <path
          className="cls-2"
          d="M21,20.68a1,1,0,0,1-.68,1.63H15a1,1,0,0,1-.95-.95V16a1,1,0,0,1,1.63-.68l1.41,1.42a9.86,9.86,0,0,1,16.49,5.29.48.48,0,0,1-.47.57H30.84a.49.49,0,0,1-.47-.36,6.67,6.67,0,0,0-11-3.25Zm12.75,5.76v5.33a1,1,0,0,1-1.63.67L30.7,31a9.85,9.85,0,0,1-16.48-5.3.47.47,0,0,1,.47-.56H17a.49.49,0,0,1,.47.36,6.67,6.67,0,0,0,11,3.24L26.8,27.12a1,1,0,0,1,.67-1.63H32.8A.94.94,0,0,1,33.75,26.44Z"
        />
      </g>
    </g>
  </svg>
);

export const GamePaused: React.SFC = () => (
  <svg version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 47.8 47.8">
    <g>
      <g id="Layer_1-2">
        <circle style={{ fill: '#4284f3' }} className="st0" cx="23.9" cy="23.9" r="22.9" />
        <path
          className="st1"
          d="M21.4,30.9h-3c-0.8,0-1.5-0.7-1.5-1.5v-11c0-0.8,0.7-1.5,1.5-1.5h3c0.8,0,1.5,0.7,1.5,1.5v11
          C22.9,30.2,22.2,30.9,21.4,30.9z M30.9,29.4v-11c0-0.8-0.7-1.5-1.5-1.5h-3c-0.8,0-1.5,0.7-1.5,1.5v11c0,0.8,0.7,1.5,1.5,1.5h3
          C30.2,30.9,30.9,30.2,30.9,29.4z"
        />
      </g>
    </g>
  </svg>
);
