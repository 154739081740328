import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import firebase from 'firebase';
import { firebaseHelper } from '../../firebaseHelper';
import { db } from '../../firebase/db.config';

const useStyles = makeStyles((theme: Theme) => ({
  wrapper: {
    width: '100%',
    height: '93%',
    [theme.breakpoints.down(750)]: {
      height: '100%',
    },
  },
}));

function YoutubePlayer({ playListId, roomName, visitor }: { playListId?: any; roomName?: any; visitor?: any }) {
  const classes = useStyles();
  var playList = playListId || 'PLx0sYbCqOb8TBPRdmBHs5Iftvv9TPboYG';
  var url = `https://www.youtube.com/embed/videoseries?list=${playList}`;

  useEffect(() => {
    var launchTime = firebase.firestore.Timestamp.now();
    var id: any;
    const updateData = async () => {
      id = await firebaseHelper(roomName);
    };
    updateData();
    return () => {
      var closeTime = firebase.firestore.Timestamp.now();
      var actionObj = {
        visitor,
        launchTime,
        closeTime,
        playListId: playList,
        feature: 'Youtube',
      };
      db.collection('roomDetails')
        .doc(id)
        .update({
          entertainmentActions: firebase.firestore.FieldValue.arrayUnion(actionObj),
        });
    };
  }, [playList]);
  return (
    <div className={classes.wrapper}>
      <iframe width="100%" height="100%" src={url}></iframe>
    </div>
  );
}

export default YoutubePlayer;
