import { SessionDetails, AccountDetails } from '../store/types';
import { ROOM_STATUS, ROOM_TYPE } from './constants';
import find from 'lodash/find';
import { initialState as sessionInititalState } from '../store/sessionDetails';
import { initialState as userInititalState } from '../store/userDetails';
import { RoomStatus, RoomTypes } from './documentModals/getUserModel';

export const generateRoomDocumentModel = ({
  activeParticipants = [],
  conversationId = '',
  hostId = '',
  participants = [],
  roomName = '',
  roomType = ROOM_TYPE.SMALL,
  timeStamp = '',
  waitingParticipants = [],
  roomId = null,
  status = ROOM_STATUS.INITIATED,
}: {
  activeParticipants: string[];
  conversationId: string;
  hostId: string;
  participants: string[];
  roomName: string;
  roomType: RoomTypes;
  timeStamp: any;
  waitingParticipants: string[];
  roomId: string | null;
  status: RoomStatus;
}) => {
  return {
    activeParticipants,
    conversationId,
    hostId,
    participants,
    roomName,
    roomType,
    timeStamp,
    waitingParticipants,
    roomId,
    status,
  };
};

export const findParticipantDetails = ({ participants, userId }: { participants: any; userId: string }) =>
  find(participants, par => par.userId === userId);

export const mapSessionStateToStore = (dbRoomDetails: any): SessionDetails => {
  const newSessionDetails: SessionDetails = {
    ...sessionInititalState,
    activeParticipants: dbRoomDetails.activeParticipants,
    allowedParticipants: dbRoomDetails.allowedParticipants,
    participants: dbRoomDetails.participants,
    // participantsDetails:dbRoomDetails.participantsDetails,
    roomAdmin: dbRoomDetails.hostId,
    roomDetails: dbRoomDetails,
    roomId: dbRoomDetails.roomId,
    roomName: dbRoomDetails.roomName,
    roomType: dbRoomDetails.roomType,
    waitingParticipants: dbRoomDetails.waitingParticipants,
    status: dbRoomDetails.status,
  };

  return {
    ...newSessionDetails,
  };
};
export const mapUserStateToStore = (dbAccountDetails: any): AccountDetails => {
  const newSessionDetails: AccountDetails = {
    ...userInititalState,
    ipAddress: dbAccountDetails?.ipAddress,
    userName: dbAccountDetails?.userName,
    // authToken,
    isHost: dbAccountDetails?.isHost,
    userDetails: dbAccountDetails,
    // userId,
  };

  return {
    ...newSessionDetails,
  };
};
