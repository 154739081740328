import { combineReducers } from '@reduxjs/toolkit';
import { RootStore } from './types';
import session from './sessionDetails';
import account from './userDetails';
import app from './app';
import createRoom from './createRoom';
import loaders from './loaders';

const rootReducer: RootStore = {
  app,
  session,
  account,
  createRoom,
  loaders,
};

export const combinedReducers = combineReducers(rootReducer);
