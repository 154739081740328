import { ROOM_STATUS, ROOM_TYPE } from '../constants';
import { firestore } from 'firebase';
import getTelehealthDocRefs from '../firebaseServices/getDocumentRefs';

type TimeStampType = ReturnType<typeof firestore.Timestamp.now>;
type DocumentData = firestore.DocumentData;
type DocumentReference<T = DocumentData> = firestore.DocumentReference<T>;
// type Docume
const { userDocRef } = getTelehealthDocRefs();

type RoomTypesKeys = keyof typeof ROOM_TYPE;
export type RoomTypes = typeof ROOM_TYPE[RoomTypesKeys];
type RoomStatusKeys = keyof typeof ROOM_STATUS;
export type RoomStatus = typeof ROOM_STATUS[RoomStatusKeys];

export type UserActivity = {
  ipAddress: string;
  timeStamp: TimeStampType;
  roomId: string;
};

export interface CreateUserModal {
  userName: string;
  userId?: string;
  rooms: string[];
  activities: UserActivity[];
  isHost: boolean;
}

export interface UserModal extends CreateUserModal {
  userId: string;
}

const generateUserDocumentModel = ({
  activities = [],
  isHost = false,
  rooms = [],
  userName = 'unknown',
  userId = userDocRef.doc().id,
}: CreateUserModal): UserModal => {
  return {
    activities,
    isHost,
    rooms,
    userName,
    userId,
  };
};

export default generateUserDocumentModel;
