import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SessionDetails } from './types';
import { RootState } from './store';
import { ROOM_STATUS, ROOM_TYPE } from '../utils/constants';

export const initialState: SessionDetails = {
  activeParticipants: [],
  participants: [],
  roomAdmin: 'none',
  roomId: null,
  roomName: '',
  roomType: ROOM_TYPE.SMALL,
  waitingParticipants: [],
  roomDetails: null,
  allowedParticipants: [],
  participantsDetails: [],
  status: ROOM_STATUS.INITIATED,
};

// middlewares
export const ROOM_DETAILS = createAsyncThunk('room/ROOM_DETAILS', (params, { getState }) => {
  // const store = getState();
  // if()
});

export const sessionSlice = createSlice({
  name: 'session',
  initialState: { ...initialState },
  reducers: {
    SET_SESSION_STATE: (state, { payload }: PayloadAction<Partial<SessionDetails>>) => {
      state.activeParticipants = payload.activeParticipants || state.activeParticipants;
      state.waitingParticipants = payload.waitingParticipants || state.waitingParticipants;
      state.allowedParticipants = payload.allowedParticipants || state.allowedParticipants;
      state.participants = payload.participants || state.participants;
      state.roomAdmin = payload.roomAdmin || state.roomAdmin;
      state.roomType = payload.roomType || state.roomType;
      state.roomName = payload.roomName || state.roomName;
      state.roomId = payload.roomId || state.roomId;
      state.roomDetails = payload.roomDetails || state.roomDetails;
      state.participantsDetails = payload.participantsDetails || state.participantsDetails;
      state.status = payload.status || state.status;
    },
    CLEAR_STATE: (state, action) => {
      Object.assign(state, initialState);
    },
  },
});

// Action creators are generated for each case reducer function
export const { SET_SESSION_STATE, CLEAR_STATE } = sessionSlice.actions;

export default sessionSlice.reducer;

// selectors
export const selectSessionState = (store: RootState) => store.session;
