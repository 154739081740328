import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SET_SESSION_STATE, selectSessionState } from '../../store/sessionDetails';
import { AccountDetails, SessionDetails } from '../../store/types';
import useJoinRoom from '../../components/VideoProvider/useJoinRoom/useJoinRoom';
import { createUser, updateIpOfUser } from '../../utils/firebaseServices/userService';
import { joinRoom } from '../../utils/firebaseServices/roomService';
import { SET_ROOM_DETAILS, selectCreateRoomState } from '../../store/createRoom';
import { RootState } from '../../store/store';
import { SET_USER_DETAILS, initialState, selectAccount } from '../../store/userDetails';
import { GetToken, telehealthtoken } from '../../firebase/cloudServices';
import useVideoContext from '../useVideoContext/useVideoContext';
import { SET_LOADER } from '../../store/loaders';
import { db } from '../../firebase/db.config';
import { COLLECTIONS } from '../../constants';
import { mapSessionStateToStore, mapUserStateToStore } from '../../utils/helper';
import { endVideoSession, leaveVideoSession } from '../../utils/endVideoSession';
import { ROOM_STATUS } from '../../utils/constants';

export default function useAccount() {
  const dispatch = useDispatch();
  // const { accountId,clearState } = useJoinRoom();
  // const { connect, disconnect } = useVideoContext();
  // const joinerDetails = useSelector((store: RootState) => selectCreateRoomState(store));
  // const sessionState = useSelector((store: RootState) => selectSessionState(store));
  // const { roomId } = useSelector((store: RootState) => selectSessionState(store));
  const accountDetails = useSelector((store: RootState) => selectAccount(store));
  const { userId } = useMemo(() => accountDetails, [accountDetails]);

  // set userDetail in createRoom state
  const setAccountState = (params: Partial<AccountDetails>) => {
    dispatch(SET_USER_DETAILS(params));
  };

  useEffect(() => {
    if (userId && userId?.trim()?.length > 0)
      db.collection(COLLECTIONS.ACCOUNT)
        .doc(userId)
        .onSnapshot(snap => {
          if (snap.exists) {
            mapUserSnapToStore(snap);
          }
        });
  }, [userId]);

  const mapUserSnapToStore = async (snap: any) => {
    try {
      const data = snap.data();
      const newAccoutDetails = mapUserStateToStore(data);

      setAccountState({
        ipAddress: newAccoutDetails.ipAddress,
        isHost: newAccoutDetails.isHost,
        userDetails: newAccoutDetails.userDetails,
        userName: newAccoutDetails.userName,
      });

      return;
    } catch (error) {
      if (error instanceof Error) console.log('Error', error.message);
      return null;
    }
  };

  return {
    setAccountState,
    userId,
  };
}
