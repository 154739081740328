import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ParticipantStatus from '../ParticipantStatus/ParticipantStatus';
import ChevronUp from '../../icons/ChevronUp';

interface ProfileProps {
  visitor?: any;
  isReconnecting?: any;
  handleLeftBtn?: any;
  showWaitingRoom?: boolean;
  roomName?: any;
  handleTerms?: any;
  roomType?: any;
  activeParticipantLength?: any;
  waitingParticipantsLength?: any;
}

const useStyles = makeStyles((theme: Theme) => ({
  profileButton: {
    background: '#232325',
    border: 'none',
    outline: 'none',
    color: 'white',
    cursor: 'pointer',
    [theme.breakpoints.up(750)]: {
      justifyContent: 'center',
      padding: '4px 0',
      // paddingRight: "8px",
      display: 'flex',
      width: '100%',
      // paddingLeft: '0px',
      // margin: "0"
    },
  },
  profileBar: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      borderBottom: '1px solid #919192',
      position: 'relative',
      bottom: '0.5%',
      width: '100%',
      justifyContent: 'space-between',
      // paddingTop: '7px',
    },
  },
  profileBar1: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'flex',
      position: 'relative',
      bottom: '0.5%',
      width: '100%',
      justifyContent: 'space-between',
      // paddingTop: '7px',
    },
  },
  profileHeading: {
    padding: '10px',
    color: 'white',
    fontSize: '15px',
    width: '68%',
  },
  profileControls: {
    display: 'flex',
    width: '32%',
    // justifyContent: 'space-between',
    justifyContent: 'space-around',
  },
  // rIconSpace: {
  //   // paddingLeft: '20px',
  //   paddingTop: '5px',
  //   [theme.breakpoints.down(750)]: {
  //     alignItems: 'center',
  //     // paddingRight: '5px',
  //     // paddingTop: '2px',
  //   },
  // },
  participantStatusMobile: {
    display: 'none',
    [theme.breakpoints.down(750)]: {
      display: 'block',
    },
  },
  audio: {
    [theme.breakpoints.up(750)]: {
      justifyContent: 'center',
      padding: '4px 0',
      display: 'flex',
      width: '100%',
      paddingLeft: '0px',
      margin: '0',
      '& button': {
        margin: '0',
        padding: '0',
      },
      '& span': {
        margin: '0',
        padding: '0',
      },
    },
  },
  video: {
    [theme.breakpoints.up(750)]: {
      justifyContent: 'center',
      padding: '4px 0',
      display: 'flex',
      width: '100%',
      paddingLeft: '0px',
      margin: '0',
      '& button': {
        margin: '0',
        padding: '0',
      },
      '& span': {
        margin: '0',
        padding: '0',
      },
    },
  },
  dFlex: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    paddingRight: '30px',
  },
}));

export default function ProfileBar({
  visitor,
  isReconnecting,
  handleLeftBtn,
  showWaitingRoom,
  roomName,
  handleTerms,
  roomType,
  activeParticipantLength,
  waitingParticipantsLength,
}: ProfileProps) {
  const classes = useStyles();
  return (
    <>
      <div className={activeParticipantLength > 1 ? classes.profileBar1 : classes.profileBar}>
        <div className={classes.dFlex}>
          <div className={classes.profileHeading}>{visitor}</div>
          <div className={classes.profileControls}>
            <div className={classes.audio}>
              <ToggleAudioButton hideText disabled={isReconnecting} />
            </div>
            <div className={classes.video}>
              <ToggleVideoButton hideText disabled={isReconnecting} />
            </div>
            {/* <SettingsMenu evisit /> */}
            <button className={classes.profileButton} onClick={handleLeftBtn}>
              {/* <span className={classes.rIconSpace}> */}
              {/* <KeyboardArrowUpIcon /> */}
              <ChevronUp />
              {/* </span> */}
            </button>
          </div>
        </div>
      </div>
      {showWaitingRoom ? (
        ''
      ) : (
        <div className={classes.participantStatusMobile}>
          <ParticipantStatus
            roomName={roomName}
            handleTerms={handleTerms}
            roomType={roomType}
            visitor={visitor}
            waitingParticipantsLength={waitingParticipantsLength}
          />
        </div>
      )}
    </>
  );
}
