import React from 'react';

export default function ScreenShareIcon({ active }: { active?: boolean }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={active ? '#4284f3' : 'white'}
      width="18"
      height="20"
      viewBox="0 0 187.5 148.23"
    >
      <g fill={active ? '#4284f3' : 'white'} id="Layer_2" data-name="Layer 2">
        <g fill={active ? '#4284f3' : 'white'} id="Layer_1-2" data-name="Layer 1">
          <path
            fill="white"
            d="M68.37,144.5a66.79,66.79,0,0,0-64-64A2.27,2.27,0,0,0,2,82.83l0,6.93a2.34,2.34,0,0,0,2.23,2.32,55.1,55.1,0,0,1,52.54,52.53,2.34,2.34,0,0,0,2.31,2.24l6.93,0a2.28,2.28,0,0,0,2.3-2.37Zm-52-12a9.19,9.19,0,1,0,0,13A9.19,9.19,0,0,0,16.36,132.54Zm29.08,12.09A44.1,44.1,0,0,0,4.27,103.47a2.26,2.26,0,0,0-2.41,2.29l0,6.95A2.35,2.35,0,0,0,4.06,115a32.35,32.35,0,0,1,29.81,29.81A2.35,2.35,0,0,0,36.19,147l7,0A2.28,2.28,0,0,0,45.44,144.63Z"
          />
          <rect width="19.72" height="66.24" rx="9.86" />
          <rect
            x="83.89"
            y="-83.89"
            width="19.72"
            height="187.5"
            rx="9.86"
            transform="translate(103.61 -83.89) rotate(90)"
          />
          <rect
            x="125.22"
            y="84.77"
            width="19.72"
            height="104.83"
            rx="9.86"
            transform="translate(272.26 2.1) rotate(90)"
          />
          <rect x="167.77" width="19.72" height="147.04" rx="9.86" />
        </g>
      </g>
    </svg>
  );
}
